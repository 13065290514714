import { getInstance } from "./axiosFactory";
import { raiseError } from "./globalActions";
const axiosInstance = getInstance();

export function loadCompaniesSuccess(companies) {
  return { type: "LOAD_COMPANIES", companies };
}

export function loadSelectedCompany(selectedCompany) {
  return { type: "COMPANY_SELECTED", selectedCompany };
}
export const getAllCompanies = (token) => {
  return (dispatch) => {
    fetchAllCompanies(token)
      .then((result) => {
        dispatch(loadCompaniesSuccess(result.data));
      })
      .catch((error) => {
        dispatch(raiseError(error));
      });
  };
};

export const refreshAllCompaniesandSelected = (token, id) => {
  return (dispatch) => {
    fetchAllCompanies(token)
      .then((result) => {
        dispatch(loadCompaniesSuccess(result.data.data));
        dispatch(
          loadSelectedCompany(result.data.data.filter((x) => x._id === id)[0])
        );
      })
      .catch((error) => {
        dispatch(raiseError(error));
      });
  };
};
export const selectCompany = (selectedCompany) => {
  return (dispatch) => {
    dispatch(loadSelectedCompany(selectedCompany));
  };
};

export const addCompany = (token, company) => {
  //serviceArea = JSON.stringify(serviceArea);
  return axiosInstance.post("/company/addcompany", company, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
};

export const modifyCompany = (token, company) => {
  //serviceArea = JSON.stringify(serviceArea);
  return axiosInstance.post("/company/modifycompany", company, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
};

export const deleteCompany = (token, company) => {
  //serviceArea = JSON.stringify(serviceArea);
  return axiosInstance.post("/company/deletecompany", company, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
};
export const fetchAllCompanies = (token) => {
  return axiosInstance.get("/company/getallcompanies", {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
};
