import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { getAllPosts } from "../../store/Actions/TestActions";
import Users from "../../components/Users";
import { Switch, Route } from "react-router-dom";
import Sidebar from "./UserDashboard/Sidebar";
import ASidebar from "./AdminDashboard/Sidebar";
import SASidebar from "./SuperAdminDashboard/Sidebar";
import MainContent from "./UserDashboard/MainContent";
import YoureModule from "../pages/Modules/YourModule";
import YoureModuleDetail from "../pages/Modules/YourModuleDetail";
import SkillPerformanace from "../pages/Skills/SkillPerformanace";
import ModuleLibrary from "../pages/Modules/ModuleLibrary";
import LibraryDetails from "../pages/Modules/LibraryDetails";
import Libraritest from "../pages/Modules/Libraritest";
import Dashboard from "./AdminDashboard/Dashboard";
import { loadState } from "../../store/LocalStorage";
import { get } from "lodash";
import UserProfile from "../pages/Profile/UserProfile";
import EmployeeProfile from "../pages/Profile/EmployeeProfile";
import UserManagementByAdmin from "../pages/UserManagement/UserManagementByAdmin";
import ModifyCompanyUser from "../pages/UserManagement/ModifyCompanyUser";
import AddCompanyUser from "../pages/UserManagement/AddCompanyUser";
import ImportUsersPage from "../pages/UserManagement/ImportUsersPage";
import ReportPage from "../pages/Report/ReportPage";
import AssignmentPage from "../pages/Modules/AssignmentPage";
import BulkAssignment from "../pages/Modules/BulkAssignment";
import CompaniesList from "../pages/Company/CompaniesList";
import AddCompany from "../pages/Company/AddCompany";
import ModifyCompany from "../pages/Company/ModifyCompany";
import UserManagement from "../pages/UserManagement/UserManagement";
import Modifyuser from "../pages/UserManagement/Modifyuser";
import DownloadModule from "../pages/Modules/DownloadModule";
import ModifyUserDetails from "../pages/UserManagement/ModifyUserDetails";
import ModulePage from "../pages/Modules/ModulePage";
import "./Deshbord.css";
import AddModule from "../pages/Modules/AddModule";
import ModifyModulepage from "../pages/Modules/ModifyModulepage";
import ReportUserpage from "../Home/UserDashboard/reportUser";
import ModuleReport from "../pages/UserManagement/ModuleReport";
import ExportUsersPage from "../pages/UserManagement/ExportUsersPage";
import SkillTableDetail from "../pages/Skills/SkillTableDetail";
import SkillPerformanaceDetail from "../pages/Skills/SkillPerformanaceDetail";
import DebriefingPage from "../pages/UserManagement/DebriefingPage";
import GlobalDashboard from "../pages/Dashboard/GlobalDashboard";
import ModuleAssignment from "../pages/Modules/ModuleAssignment";
import TestREportPg from "../pages/Report/TestREportPg";
import OasisConfiguration from "../pages/oasis";

const Home = (state) => {
  const [userInfo, setuserInfo] = useState("");
  const dispatch = useDispatch();
  const { loggedIn, user, token, modules } = get(loadState(), "auth") || {};

  let auth = {
    token: token || "",
    loggedIn: loggedIn || false,
    modules: modules || [],
    user: {
      ...user,
    },
  };
  useEffect(() => {
    // dispatch(getAllPosts());
    setuserInfo(auth.user.role);

    // console.log(
    //   "CHECK",
    //   JSON.parse(sessionStorage.getItem("reloaded")) !== null
    // );

    // console.log(JSON.parse(localStorage.getItem("isAuthenticated")) === false);

    // if (
    //   JSON.parse(sessionStorage.getItem("reloaded")) === true &&
    //   JSON.parse(localStorage.getItem("isAuthenticated")) === false
    // ) {
    //   console.log("reload!!!");
    //   window.location = "/signin";
    // }
  }, []);

  return (
    <>
      <section className="main-wrap">
        <div className="container-fluid p-0">
          {userInfo === "User" ? (
            <div className="wrapper">
              <Sidebar />
              <Switch>
                <Route exact path="/" component={MainContent} />
                <Route path="/user-dashboard" component={MainContent} />
                <Route path="/your-module" component={YoureModule} />
                <Route
                  path="/your-module-detail/:id"
                  component={YoureModuleDetail}
                />
                <Route path="/skills" component={SkillPerformanace} />
                <Route
                  path="/skill-detail/:string"
                  component={SkillPerformanaceDetail}
                />
                <Route path="/module-library" component={ModuleLibrary} />
                <Route path="/library-details" component={LibraryDetails} />
                <Route path="/test-details" component={Libraritest} />
                <Route path="/profile" component={EmployeeProfile} />
                <Route path="/user_report" component={ReportUserpage} />

                <Route
                  path="/library-details-download"
                  component={DownloadModule}
                />
              </Switch>
            </div>
          ) : userInfo === "Admin" ? (
            <div className="wrapper">
              <ASidebar />
              <Switch>
                <Route exact path="/" component={Dashboard} />
                <Route path="/dashboard" component={Dashboard} />
                <Route
                  path="/company-user-management"
                  component={UserManagementByAdmin}
                />
                <Route path="/user-management" component={UserManagement} />
                {/* <Route path='/company-user-management' component={UserManageTable}/>  */}
                <Route path="/skills" component={SkillPerformanace} />
                <Route
                  path="/skill-detail/:string"
                  component={SkillPerformanaceDetail}
                />
                <Route path="/profile" component={EmployeeProfile} />
                <Route
                  path="/oasis/configuration"
                  component={EmployeeProfile}
                />
                <Route
                  exact
                  path="/modify-companyuser"
                  component={ModifyCompanyUser}
                />
                <Route path="/modify-user" component={Modifyuser} />
                <Route
                  path="/modify-user-details"
                  component={ModifyUserDetails}
                />
                <Route path="/module-report/:id" component={ModuleReport} />
                <Route path="/add-companyuser" component={AddCompanyUser} />
                <Route path="/import-user" component={ImportUsersPage} />
                <Route path="/export-user" component={ExportUsersPage} />
                <Route path="/module-library" component={ModuleLibrary} />
                <Route path="/library-details" component={LibraryDetails} />
                <Route path="/report" component={ReportPage} />
                <Route path="/assignment" component={AssignmentPage} />
                <Route path="/bulk-assignment" component={BulkAssignment} />
                <Route
                  path="/library-details-download"
                  component={DownloadModule}
                />
              </Switch>
            </div>
          ) : userInfo === "SuperAdmin" ? (
            <div className="wrapper">
              <SASidebar />
              <Switch>
                <Route exact path="/" component={GlobalDashboard} />
                <Route path="/departments" component={CompaniesList} />
                <Route path="/addnewcompany" component={AddCompany} />
                <Route path="/profile" component={EmployeeProfile} />
                <Route
                  path="/oasis/configuration"
                  component={OasisConfiguration}
                />
                <Route path="/modifydepartment/:id" component={ModifyCompany} />
                <Route path="/user-management" component={UserManagement} />
                <Route
                  exact
                  path="/modify-companyuser"
                  component={ModifyCompanyUser}
                />
                <Route path="/modify-user" component={Modifyuser} />
                <Route
                  path="/modify-user-details"
                  component={ModifyUserDetails}
                />
                <Route path="/module-report/:id" component={ModuleReport} />
                <Route path="/module-report" component={ModuleReport} />

                <Route
                  path="/library-details-download"
                  component={DownloadModule}
                />
                <Route path="/add-companyuser" component={AddCompanyUser} />
                <Route path="/import-user" component={ImportUsersPage} />
                <Route path="/export-user" component={ExportUsersPage} />
                <Route path="/report" component={ReportPage} />
                {/* <Route path="/report" component={TestREportPg} /> */}
                <Route path="/module" component={ModulePage} />
                <Route path="/assignment" component={AssignmentPage} />
                <Route path="/bulk-assignment" component={BulkAssignment} />
                <Route path="/add-module" component={AddModule} />
                <Route path="/module-management" component={ModifyModulepage} />
                <Route path="/debriefing" component={DebriefingPage} />
                {/* <Route path="/dashboard" component={GlobalDashboard} /> */}
              </Switch>
            </div>
          ) : (
            <div className="wrapper">
              <SASidebar />
              <Switch>
                <Route exact path="/" component={GlobalDashboard} />
                <Route path="/departments" component={CompaniesList} />
                <Route path="/addnewcompany" component={AddCompany} />
                <Route path="/profile" component={OasisConfiguration} />
                <Route path="/modifydepartment/:id" component={ModifyCompany} />
                <Route path="/user-management" component={UserManagement} />
                <Route path="/export-user" component={ExportUsersPage} />
                <Route
                  exact
                  path="/modify-companyuser"
                  component={ModifyCompanyUser}
                />
                <Route path="/modify-user" component={Modifyuser} />
                <Route
                  path="/modify-user-details"
                  component={ModifyUserDetails}
                />
                <Route path="/module-report/:id" component={ModuleReport} />
                <Route path="/module-report" component={ModuleReport} />

                <Route
                  path="/library-details-download"
                  component={DownloadModule}
                />
                <Route path="/add-companyuser" component={AddCompanyUser} />
                <Route path="/import-user" component={ImportUsersPage} />
                <Route path="/report" component={ReportPage} />
                <Route path="/module" component={ModulePage} />
                <Route path="/assignment" component={AssignmentPage} />
                <Route path="/bulk-assignment" component={BulkAssignment} />
                <Route path="/add-module" component={AddModule} />
                <Route path="/module-management" component={ModifyModulepage} />
                {/* <Route path="/dashboard" component={GlobalDashboard} /> */}
                <Route path="/module-assignment" component={ModuleAssignment} />
                <Route path="/debriefing" component={DebriefingPage} />
              </Switch>
            </div>
          )}
        </div>
      </section>
    </>
  );
};

export default Home;
