import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import EldcLogo from "../../../images/MedVR_Logo.png";
import { Link, useHistory } from "react-router-dom";
import Ellipse9 from "../../../images/Ellipse9.png";
import { get } from "lodash";
import { bindActionCreators } from "redux";
import * as moduleActions from "../../../action/moduleActions";
import Background from "../../../images/company-profile-bg.jpg";
import axios from "axios";
import moment from "moment";
import * as userActions from "../../../action/userActions";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { useParams } from "react-router-dom";
import { getModuleReport } from "../../../action/moduleActions";
import MobileNavBar from "../../../components/mobileNavBar";
import { HorizontalBarChart } from "../../../common/chart";
import {
  Bar,
  BarChart,
  CartesianGrid,
  Label,
  LabelList,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { BsEyeFill } from "react-icons/bs";
import {
  renderInputField,
  renderRadioButtonField,
} from "../../../common/InputFields";


const TestREportPg = (props) => {
  const { handleSubmit, auth, token, user, moduleActions, module } = props;
  const [key, setKey] = useState("home");
  const [selectedCompetencyName, setSelectedCompetencyName] = useState("");
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  let params = useParams();

  const selectModel = localStorage.getItem("selectedModule");
  const [selectModule, setSelectModule] = useState(
    selectModel === null ? undefined : JSON.parse(selectModel)
  );
  const [profilePic, setProfilePic] = useState(selectModule.profilePic);
  const [competencyDetails, setCompetencyDetails] = useState([]);
  const [filteredCompetencyDetails, setFilteredCompetencyDetails] = useState([]);
  const [selectedCompetency, setSelectedCompetency] = useState([]);

  useEffect(() => {
    // Load data or handle initial actions
    moduleActions.getModuleReport(params.id, selectModule._id);
  }, [params.id, selectModule._id]);

  const handleSearch = () => {
    if (selectedCompetencyName && fromDate && toDate) {
      const filteredData = module?.moduleReport?.competencyReport.filter(
        (item) =>
          item.competencyName === selectedCompetencyName &&
          item.createdAt >= fromDate &&
          item.createdAt <= toDate
      );
      setSelectedCompetency(filteredData);
    }
  };

  useEffect(() => {
    let filteredCompetencyReport =
      module?.moduleReport?.competencyReport.reduce(function (r, a) {
        r[a.competencyName] = r[a.competencyName] || [];
        r[a.competencyName].push(a);
        return r;
      }, Object.create(null));
    setFilteredCompetencyDetails(filteredCompetencyReport);
  }, [module]);

  const options = Object.keys(filteredCompetencyDetails).map((key) => (
    <option value={key}>{key}</option>
  ));

  const maxBarWidth = 20;

  // Helper function to calculate the bar width based on the number of data items
  const calculateBarWidth = (dataLength) => {
    // Calculate the desired width based on the number of data items and the maximum width
    const desiredWidth = Math.min(maxBarWidth, 600 / dataLength);
    return Math.max(desiredWidth, 1); // Ensure a minimum width of 1 pixel
  };

  const CustomBar = ({ fill, x, y, width, height }) => {
    return <rect fill={fill} x={x} y={y} width={width} height={height} rx={4} />;
  };

  return (
    <>
      <div className="overview-cont">
        <div className="row">
          <div className="col-xl-12 col-lg-12 overview-left-area your-module">
            {/* ... (rest of the JSX) */}

            <Tabs
              id="controlled-tab-example"
              activeKey={key}
              onSelect={(k) => setKey(k)}
              className="mb-3 nav-pills nav-fill"
            >
              <Tab eventKey="home" title="Competency Details">
                <div className="row mt-4">
                  <div className="col-xl-12">
                    <div className="modular-table big-table table-responsive p-4">
                      <div className="sec2-header">
                        {/* ... (rest of the JSX) */}

                        <BarChart
                          width={700}
                          height={300}
                          data={selectedCompetency.map((item) => ({
                            ...item,
                          }))}
                          margin={{
                            top: 5,
                            right: 30,
                            left: 50,
                            bottom: 5,
                          }}
                          barSize={20} // Set the width of the bars
                        >
                          <CartesianGrid strokeDasharray="3 3" />
                          <XAxis
                            dataKey="createdAt"
                            tickFormatter={(timestamp) => new Date(timestamp).toLocaleDateString()}
                            angle={-45}
                            textAnchor="end"
                          />
                          <YAxis type="number" domain={[0, 1]} axisLine={false} />
                          <Tooltip
                            cursor={{ fill: "transparent" }}
                            content={({ payload, label, active }) => {
                              // ... (existing tooltip code)
                            }}
                          />
                          <Bar dataKey="averageCompetencyScore">
                            <LabelList
                              dataKey="averageCompetencyScore"
                              content={({ x, y, width, value }) => (
                                <text
                                  x={x + width + 5} // Adjust the text position
                                  y={y + 10} // Adjust the text position
                                  fill="#8884d8"
                                  textAnchor="start"
                                >
                                  {value}
                                </text>
                              )}
                            />
                          </Bar>
                        </BarChart>

                      </div>
                    </div>
                  </div>
                </div>
              </Tab>
            </Tabs>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  user: state.auth.user,
  initialValues: state.users.selectedUser,
  token: state.auth.token,
  module: state.modules,
});

const mapDispatchToProps = (dispatch) => {
  return {
    moduleActions: bindActionCreators(moduleActions, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TestREportPg);
