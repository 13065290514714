import axios from "axios";
import { API_URL } from "../config";
import { loadState, saveState } from "../store/LocalStorage";
import { get } from "lodash";
import configureStorenew from "../store/configureStore";

export const getInstance = () => {
  const instance = axios.create({
    baseURL: API_URL,
  });

  instance.interceptors.response.use(
    function (response) {
      // Do something with response data

      return response;
    },
    function (error) {
      // Do something with response error
      if (
        get(error, "response.data.message") === "jwt expired" ||
        get(error, "response.status") === 401
      ) {
        // const store = configureStorenew();
        // store.dispatch({ type: 'LOGOUT' });
        //if (window) window.location = './login';
        saveState({});
      }
      return Promise.reject(error);
    }
  );

  instance.interceptors.request.use(
    function (config) {
      // Do something with response data
      delete config.headers.Authorization;
      delete config.headers.authorization;
      config.headers.Authorization = "Bearer " + get(loadState(), "auth.token");
      return config;
    },
    function (error) {
      // Do something with response error

      return Promise.reject(error);
    }
  );

  return instance;
};
