import React, { useEffect, useState } from "react";
import Background from "../../images/company-profile-bg.jpg";
import Ellipse9 from "../../images/Ellipse9.png";
import camera from "../../images/camera.svg";
import eye from "../../images/eye-regular.svg";
import * as userActions from "../../action/userActions";
import { Link, useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { toast } from "react-toastify";
import { get } from "lodash";
import Swal from "sweetalert2";
import "react-toastify/dist/ReactToastify.css";
import {
  getAllDepartments,
  getDepartment,
  getOasisDetails,
  updateOasisDetails,
} from "../../action/departmentActions";
import MobileNavBar from "../../components/mobileNavBar";
import Search from "../common/Search";

let OasisConfiguration = (props) => {
  const history = useHistory();
  const { auth, user, token } = props;
  const [departments, setDepartments] = useState([]);
  const [selectedDepartments, setSelectedDepartments] = useState([]);
  const [oasis, setOasis] = useState({});

  useEffect(() => {
    getAllDepartments().then((result) => setDepartments(result.data));
    getOasisDetails().then((response) => {
      setOasis(response.data);
      setSelectedDepartments(response.data.departments);
    });
  }, []);

  const onDepartmentItemSelected = (department) => {
    if (
      !selectedDepartments.find(
        (selectedDepartment) => selectedDepartment._id === department._id
      )
    )
      setSelectedDepartments([...selectedDepartments, department]);
  };

  const onRemoveModuleClick = (e, departmentId) => {
    e.stopPropagation();
    const newSelectedDepartments = selectedDepartments.filter(
      (item) => item._id !== departmentId
    );
    setSelectedDepartments([...newSelectedDepartments]);
  };

  const handleSubmit = async (e) => {
    if (oasis.api_account && oasis.api_password && oasis.identifier_field) {
      let departmentArr = [];
      for (let i = 0; i < selectedDepartments.length; i++) {
        departmentArr.push(selectedDepartments[i]._id);
      }
      oasis.departments = departmentArr;

      updateOasisDetails(oasis)
        .then(() => {
          Swal.fire({
            title: "Success",
            text: "Oasis configuration updated successfully!",
            icon: "success",
            confirmButtonText: "Ok",
            allowOutsideClick: false,
          }).then(function () {
            // history.push("/");
          });
        })
        .catch((err) => {
          Swal.fire({
            title: "Error",
            text: `Error: ${err}`,
            icon: "error",
            confirmButtonText: "Ok",
            allowOutsideClick: false,
          });
        });
    } else {
      Swal.fire({
        title: "Error",
        text: "Please enter all fields",
        icon: "error",
        confirmButtonText: "Ok",
        allowOutsideClick: false,
      });
    }
  };

  const handleChange = (e) => {
    let updatedOasis = Object.assign({}, oasis);
    updatedOasis[e.target.name] = e.target.value;
    setOasis(updatedOasis);
  };

  var profileBackgroundStyle = {
    width: "100%",
    backgroundImage: `url(${Background})`,
  };

  return (
    <>
      <div className="overview-cont">
        <div className="row">
          <div className="col-xl-12 col-lg-12 overview-left-area your-module">
            <MobileNavBar />
            <div className="d-flex align-items-center justify-content-between">
              <h2 className="right_hading d-none d-sm-inline">
                Oasis Configuration
              </h2>

              <div className="mt-3">
                <Link to="/" className="adcm-btn Cancel ms-2 align-bottom">
                  <span>Return</span>
                </Link>

                <button
                  onClick={() => handleSubmit()}
                  className="btn adcm-btn ms-2"
                  style={{ color: "#fff" }}
                >
                  Save
                </button>
              </div>
            </div>
            <h2 className="right_hading d-sm-none d-block">Add a New Module</h2>
            <div className="row">
              <div className="col-lg-12">
                <div className="company-main m-prof">
                  <div
                    className="company-profile"
                    style={profileBackgroundStyle}
                  ></div>
                  <div className="company-fillout">
                    <h3 className="fill-heaing">API Information</h3>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>API Account</label>
                          <input
                            type="text"
                            name="api_account"
                            className="form-control"
                            placeholder="API Account Name"
                            value={oasis.api_account}
                            onChange={handleChange}
                          />
                        </div>
                        <div className="form-group ">
                          <label className="mt-3">Identifier Field</label>
                          <select
                            name="identifier_field"
                            onChange={handleChange}
                            className="form-select form-control"
                            aria-label="Default select example"
                            value={oasis.identifier_field}
                          >
                            <option value="user_id">User ID</option>
                            <option value="external_system_id">
                              External System ID
                            </option>
                            <option value="login_name">Login Name</option>
                            <option value="email_address">Email Address</option>
                            <option value="integration_field_1">
                              Integration Field 1
                            </option>
                          </select>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>API Password</label>
                          <input
                            type="text"
                            name="api_password"
                            className="form-control"
                            placeholder="API Password"
                            value={oasis.api_password}
                            autocomplete="off"
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="company-fillout">
                    <h3 className="fill-heaing">Department Information</h3>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>Departments</label>
                          <div className="form-group">
                            <div className="field-group">
                              <Search
                                {...{
                                  data: departments,
                                  onItemSelected: onDepartmentItemSelected,
                                  populateFieldName: "departmentName",
                                  placeholderLabel: "Select Departments",
                                }}
                              />
                            </div>
                          </div>
                          <div className="flex2fieldForm">
                            <div className="field-group">
                              {selectedDepartments &&
                                selectedDepartments.length > 0 && (
                                  <div className="flex2fieldForm">
                                    <div className="field-group">
                                      <div
                                        className="radioGroup"
                                        style={{ paddingTop: "2.25em" }}
                                      >
                                        {selectedDepartments.map(
                                          (item, index) => (
                                            <div
                                              key={`${item}-${index}`}
                                              className="mr-4 moduleCompetencyCard"
                                            >
                                              <label htmlFor="Module">
                                                {item.departmentName}
                                              </label>
                                              <button
                                                onClick={(e) =>
                                                  onRemoveModuleClick(
                                                    e,
                                                    item._id
                                                  )
                                                }
                                              >
                                                X
                                              </button>
                                            </div>
                                          )
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    user: state.auth.user,
    token: state.auth.token,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    userActions: bindActionCreators(userActions, dispatch),
    dispatch: dispatch,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(OasisConfiguration);
