import React, { useEffect, useState } from "react";
import { Link, Redirect } from "react-router-dom";
import Swal from "sweetalert2";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import axios from "axios";
import { saveState, loadState } from "../../store/LocalStorage";
import * as authActions from "../../action/authActions";
import { get } from "lodash";
import Background from "../../images/Login-bg.jpg";
import whitelogo from "../../images/MedVR_Logo.png";
import pexelsphoto from "../../images/Platform_Banner.jpg";
import padlock from "../../images/padlock.svg";
import envolope from "../../images/envolope.svg";
import eye from "../../images/eye.svg";
import "./loginmain.css";

let Signin = (props) => {
  const { auth } = props;
  // export default function Signin(props) {
  // const [auth2, setauth] = useState(sessionStorage.getItem('SeAuth'))
  const [formData, setName] = useState({ mobileNo: "", passwordHash: "" });
  const { loggedIn, user, token, modules, loggedInError } =
    get(loadState(), "auth") || {};
  const [keepMeLogin, setKeepMeLogin] = useState(false);
  const [passwordShown, setPasswordShown] = useState(false);

  useEffect(() => {
    if (typeof window !== "undefined") {
      localStorage && localStorage.setItem("keepMeLogin", keepMeLogin);
      if (!keepMeLogin) {
        sessionStorage && sessionStorage.setItem("reloaded", true);
      } else {
        sessionStorage && sessionStorage.removeItem("reloaded");
      }
    }
  }, [keepMeLogin]);

  const Loginsubmit = async (e) => {
    e.preventDefault();
    props.authActions
      .login(formData.mobileNo, parseInt(formData.passwordHash))
      .then((result) => {
        if (!JSON.parse(localStorage.getItem("isAuthenticated")))
          Swal.fire({
            allowOutsideClick: false,
            title: "Login failed!",
            text: "User ID and Password do not match.",
            icon: "warning",
            confirmButtonColor: "#15bd8c",
            confirmButtonText: "Ok",
          }).then(localStorage.setItem("isAuthenticated", true));
      });
  };

  const handleChange = (e) => {
    setName({ ...formData, [e.target.name]: e.target.value });
  };
  var sectionStyle = {
    width: "100%",

    backgroundImage: `url(${Background})`,
  };
  const togglePassword = () => {
    setPasswordShown(!passwordShown);
  };

  return (
    <section className="ftco-section">
      <div class="logo">
        <img src={whitelogo} style={{ width: "200px", marginTop: "2em" }} />
      </div>
      <div className="container-fliud">
        <div className="row align-items-center">
          <div className="col-md-6 px-0">
            <div className="login-wrap">
              <form
                action="#"
                className="signin-form custom-form"
                onSubmit={Loginsubmit}
              >
                <div className="form-group mb-3">
                  <div className="icon d-flex align-items-center justify-content-center">
                    <img src={envolope} alt="" />
                  </div>
                  <input
                    type="text"
                    className="form-control"
                    name="mobileNo"
                    value={formData.mobileNo}
                    placeholder="User ID"
                    onChange={handleChange}
                    size="45"
                    id="mobileNo"
                    title=" mobileNo"
                    required
                  />
                </div>
                <div className="form-group mb-3">
                  <div className="icon d-flex align-items-center justify-content-center">
                    <img src={padlock} alt="" />
                  </div>
                  {/* <input id="password-field" type="password" className="form-control" placeholder="Password" required /> */}
                  <input
                    type={passwordShown ? "text" : "password"}
                    id="password-field"
                    name="passwordHash"
                    value={formData.passwordHash}
                    onChange={handleChange}
                    placeholder="Password"
                    className="form-control"
                    required
                  />
                  <img
                    src={eye}
                    className="toggle-password"
                    onClick={togglePassword}
                    alt=""
                  />
                  {/* <span toggle="#password-field" className="fa fa-fw fa-eye field-icon toggle-password"></span> */}
                </div>
                <div className="form-check mb-3">
                  <label className="form-check-label" for="form2Example31">
                    {/* <input className="form-check-input me-2" type="checkbox" value="" id="form2Example31" checked="" /> Keep Me Logged In </label> */}
                    <input
                      type="checkbox"
                      className="form-check-input me-2"
                      value={keepMeLogin}
                      onChange={() => setKeepMeLogin(!keepMeLogin)}
                    />
                    Remember Me
                  </label>
                </div>
                <div className="mb-2">
                  <button type="submit" className="form-control submit px-3">
                    LOG IN
                  </button>
                </div>
              </form>
            </div>
            <div
              style={{
                maxWidth: "370px",
                margin: "20px auto",
                textAlign: "center",
              }}
            >
              <p>
                Building the world's largest XR healthcare simulation library
              </p>
            </div>
          </div>
          <div className="col-md-6 px-0 right-im-box">
            <img className="" src={pexelsphoto} />
          </div>
        </div>
      </div>
    </section>
  );
  // }else{
  //   return (<Redirect to="/"></Redirect>)
  // }
};
const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    companyDefaults: state.companyDefaults,
  };
};

const mapDispatchToProps = (dispatch) => {
  return { authActions: bindActionCreators(authActions, dispatch) };
};

// Login = reduxForm({
//   form: 'loginform',
//   validate
// })(Login)
export default connect(mapStateToProps, mapDispatchToProps)(Signin);
