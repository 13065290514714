import { get } from "lodash";
import React from "react";
import {
  Bar,
  BarChart,
  CartesianGrid,
  Label,
  LabelList,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";

import "./barChart.css";

const colors = [
  "#8FFF67",
  "red",
  "grey",
  "blue",
  "purple",
  "yellow",
  "black",
  "pink",
];
export const HorizontalBarChart = (props) => {
  let {
    data,
    dataKey = 50,
    barColor,
    barColors,
    barSize = 80,
    axisLine = true,
    tickLineXaxis = true,
  } = props;
  let keys =
    data && data.length > 0
      ? Object.keys(data[0]).filter((key) => key !== dataKey)
      : [];

  return (
    <BarChart
      data={data}
      barCategoryGap={100}
      barGap={60}
      margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
      height={props.height}
      width={props.width}
    >
      <CartesianGrid strokeDasharray="3 3" />
      <XAxis
        type="category"
        dataKey={dataKey}
        axisLine={axisLine}
        tickLine={tickLineXaxis}
      />
      <YAxis type="number" domain={[0, 100]} axisLine={axisLine} />
      <Tooltip cursor={{ fill: "transparent" }} />
      <Legend />
      {keys
        ? keys.map((key, index) => (
            <Bar
              key={index}
              dataKey={key}
              fill={barColor || barColors[index] || colors[index]}
              // barSize={barSize}
              radius={[3, 3, 0, 0]}
            />
          ))
        : null}
    </BarChart>
  );
};

export const HorizontalBarChartToday = (props) => {
  let {
    data,
    dataKey = 20,
    barColor,
    barColors,
    barSize = 20,
    axisLine = false,
    tickLineXaxis = false,
  } = props;

  let keys =
    data && data.length > 0
      ? Object.keys(data[0]).filter((key) => key !== dataKey)
      : [];

  return (
    <BarChart
      data={data}
      margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
      height={props.height}
      width={props.width}
    >
      <CartesianGrid strokeDasharray="4 4" />
      <XAxis
        type="category"
        dataKey={dataKey}
        axisLine={axisLine}
        tickLine={tickLineXaxis}
      />
      <YAxis type="number" domain={[0, 20]} axisLine={axisLine} />
      <Tooltip cursor={{ fill: "transparent" }} />

      <Legend />
      {keys
        ? keys.map((key, index) => (
            <Bar
              key={index}
              dataKey={key}
              fill={barColor || barColors[index] || colors[index]}
              barSize={barSize}
              radius={[6, 6, 0, 0]}
            />
          ))
        : null}
    </BarChart>
  );
};

export const HorizontalNewBarchart =(props) =>{
  const colors = [
    "#8FFF67",
    "red",
    "grey",
    "blue",
    "purple",
    "yellow",
    "black",
    "pink",
  ];
  return (
    <BarChart
      width={500}
      height={300}
      data={props.data}
      margin={{
        top: 5,
        right: 30,
        left: 20,
        bottom: 5
      }}
    >
      <CartesianGrid strokeDasharray="3 3" />
      <XAxis dataKey="name" />
      <YAxis />
      <Tooltip />
      <Legend />
      <Bar dataKey="pv" fill="#8884d8" />
      {/* <Bar dataKey="uv" fill="#82ca9d" /> */}
    </BarChart>
    );
}

export const VerticalBarChart = (props) => {
  let { data, dataKey, axisLine = true, barColor } = props;
  let keys =
    data && data.length > 0
      ? Object.keys(data[0]).filter((key) => key !== dataKey)
      : [];
  return (
    <BarChart
      data={data}
      margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
      layout="vertical"
      height={props.height}
      width={props.width}
    >
      <CartesianGrid strokeDasharray="3 3" />
      <XAxis type="number" axisLine={axisLine} />
      <YAxis type="category" dataKey={dataKey} axisLine={axisLine} />
      <Tooltip />
      <Legend />

      {keys
        ? keys.map((key, index) => (
            <Bar key={index} dataKey={key} fill={barColor || colors[index]} />
          ))
        : null}
    </BarChart>
  );
};

const renderCustomizedLabel = (props) => {
  const { content, ...rest } = props;

  return <Label {...rest} fontSize="20" fill="#FFFFFF" fontWeight="Bold" />;
};

const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    return (
      <div className="custom-tooltip">
        <p className="label">{label}</p>
        {payload.map((item) => (
          <p
            className="spend-time"
            style={{ color: item.color }}
          >{`${item.dataKey}: ${item.value} min`}</p>
        ))}
      </div>
    );
  }

  return null;
};

export const StackedBarChart = (props) => {
  const colors = ["#4697e9", "#42b0b7", "#a69f39", "#d5b900", "#26bfc9", "#152493", "#73bc4b"];
  const {
    data = [
      {},
      {},
      {},
      {},
      {},
      {},
      {},
      {},
      {},
      {},
      {},
      {},
      {},
      {},
      {},
      {},
      {},
      {},
      {},
      {},
      {},
      {},
      {},
      {},
      {},
      {},
      {},
      {},
      {},
      {},
    ],
    moduleList,
    duarations = [],
  } = get(props, "stackChartData", {});

  return (
    <div className="content c-white">
      <ResponsiveContainer height={300} width={"100%"}>
        <BarChart
          layout="horizontal"
          data={data}
          margin={{ left: 0, right: 0 }}
        >
          <CartesianGrid strokeDasharray="2 2" stroke="lightgrey" />
          <XAxis type="category" dataKey="date" stroke="black" fontSize="12" />
          <YAxis type="number" stroke="black" />
          <Tooltip cursor={{ fill: "transparent" }} content={CustomTooltip} />
          <Legend />
          {moduleList &&
            moduleList.map((item, index) => (
              <Bar
                key={item}
                dataKey={item}
                fill={
                  colors.length > index
                    ? colors[index]
                    : colors[Math.random() * colors.length]
                }
                stackId="a"
              ></Bar>
            ))}
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};
