import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import Ellipse9 from "../../../images/Ellipse9.png";
import { get } from "lodash";
import { bindActionCreators } from "redux";
import * as moduleActions from "../../../action/moduleActions";
import Background from "../../../images/company-profile-bg.jpg";
import moment from "moment";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { useParams } from "react-router-dom";
import MobileNavBar from "../../../components/mobileNavBar";
import Swal from "sweetalert2";
import { CSVLink } from "react-csv";

import {
  Bar,
  BarChart,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { BsEyeFill } from "react-icons/bs";
import {
  renderInputField,
  renderRadioButtonField,
} from "../../../common/InputFields";

const ModuleReport = (props) => {
  const { handleSubmit, auth, token, user, moduleActions, module } = props;
  const [key, setKey] = useState("assessment");
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  let params = useParams();

  const selectModel = localStorage.getItem("selectedModule");
  const [selectModule, setSelectModule] = useState(
    selectModel === null ? undefined : JSON.parse(selectModel)
  );
  const [profilePic, setProfilePic] = useState(selectModule.profilePic);
  const [competencyDetails, setCompetencyDetails] = useState([]);
  const [filteredCompetencyDetails, setFilteredCompetencyDetails] = useState(
    []
  );
  const [selectedCompetency, setSelectedCompetency] = useState([]);
  const [selectedCompetencyName, setSelectedCompetencyName] = useState("");
  const history = useHistory();
  const selectedUserid = localStorage.getItem("userSelectId");

  let [modulesSingle, setModulesSingle] = useState(null);
  // let [competencyData, setcompetencyData]

  var modifyBackgroundStyle = {
    width: "100%",
    backgroundImage: `url(${Background})`,
  };

  useEffect(() => {
    moduleActions.getModuleReport(params.id, selectModule._id);
    moduleActions.getModuleTrainingReport(params.id, selectModule._id);

    localStorage.setItem("modid", params.id);
  }, [params.id, selectModule._id]);

  useEffect(() => {
    InitialGraph();
    let fileteredCompetencyReport =
      module?.moduleReport?.competencyReport.reduce(function (r, a) {
        r[a.competencyName] = r[a.competencyName] || [];
        r[a.competencyName].push(a);
        return r;
      }, Object.create(null));
    setFilteredCompetencyDetails(fileteredCompetencyReport);
  }, [module]);

  const InitialGraph = () => {
    setSelectedCompetencyName(
      module.moduleReport.competencyReport[0]?.competencyName
    );

    const startDate = new Date();
    startDate.setDate(startDate.getDate() - 30);

    const formattedStartDate = startDate.toISOString().slice(0, 10);

    const today = new Date().toISOString().slice(0, 10);

    const filteredData = module?.moduleReport?.competencyReport.filter(
      (item) =>
        item.competencyName ===
          module.moduleReport.competencyReport[0]?.competencyName &&
        item.createdAt >= formattedStartDate &&
        item.createdAt <= today
    );
    setSelectedCompetency(filteredData);

    setCsvFn(filteredData);
  };

  const handleSearch = () => {
    if (fromDate === "" || toDate === "") {
      Swal.fire({
        title: "Error",
        text: `Error: ${JSON.stringify(`Please select a date range !`)}`,
        icon: "error",
        confirmButtonText: "Ok",
        allowOutsideClick: false,
      });
    } else {
      if (selectedCompetencyName && fromDate && toDate) {
        var newToDate = moment(toDate, "YYYY-MM-DD")
          .add(1, "days")
          .format("YYYY-MM-DD");

        const filteredData = module?.moduleReport?.competencyReport.filter(
          (item) =>
            item.competencyName === selectedCompetencyName &&
            item.createdAt >= fromDate &&
            item.createdAt <= newToDate
        );
        setSelectedCompetency(filteredData);

        setCsvFn(filteredData);
      }
    }
  };

  const setCsvFn = (filteredData) => {
    // Set CSV
    let csvData = [];
    filteredData.forEach((fData) => {
      if (fData) {
        csvData.push({
          Username: selectModule.name,
          Email: selectModule.emailAddress,
          "Module Name": fData.competencyName,
          "Date / Time":
            new Date(fData.updatedAt).toLocaleDateString() +
            " - " +
            new Date(fData.updatedAt).toLocaleTimeString(),
          Duration: getDurationString(fData.duration),
          Mode: fData.mode,
          Score: `${(fData.averageCompetencyScore * 100).toFixed(0)}%`,
        });
      }
    });

    setCsvReportData(csvData);
  };

  const options = Object.keys(filteredCompetencyDetails).map((key) => (
    <option value={key}>{key}</option>
  ));

  const getDurationString = (d) => {
    let x = d.toString().split(".");

    x[0] = parseInt(x[0]);
    x[1] = parseInt(x[1].slice(0, 2)) / 100;

    let durationString = x[0] + " hrs " + (x[1] * 60).toFixed(0) + " mins";
    return durationString;
  };
  const maxBarwidth = 100;

  const DeberifingData = (modId, sessionId) => {
    localStorage.setItem("ModuleId", modId);
    localStorage.setItem("sessionId", sessionId);
  };

  // CSV
  const [csvReportData, setCsvReportData] = useState([]);

  return (
    <>
      <div className="overview-cont">
        <div className="row">
          <div className="col-xl-12 col-lg-12 overview-left-area your-module">
            <MobileNavBar />
            <div className="d-flex justify-content-between align-items-center">
              <h2 className="right_hading d-none d-md-inline">User Report</h2>
              <Link
                to={`/modify-user-details?user_id=${selectedUserid}`}
                className="adcm-btn Cancel ms-1 force-return-but"
              >
                <span>Return</span>
              </Link>
              {/* <button
                  className="adcm-btn Cancel ms-1"
                  onClick={() => window.history.back()}
                >
                  
                  Return
                </button> */}
            </div>
            <h2 className="right_hading d-md-none d-block">User Report</h2>

            <div className="row">
              <div className="col-lg-12">
                <div className="company-main user-report">
                  <form action="">
                    <div
                      className="company-profile"
                      style={modifyBackgroundStyle}
                    ></div>
                    <div className="company-fillout">
                      <div className="circle-outer profile-image-circle mb-3 mb-md-0">
                        <div className="circle">
                          {/* <img className="profile-pic" src="assets/images/Ellipse9.png" alt="" /> */}
                          <img
                            className="profile-pic"
                            src={get(profilePic, "Location") || Ellipse9}
                            alt=""
                          />
                        </div>
                        <div className="p-image">
                          <img
                            className="upload-button"
                            src="assets/images/camera.svg"
                            alt=""
                          />
                          <input
                            className="file-upload"
                            type="file"
                            accept="image/*"
                          />
                        </div>
                      </div>
                      <div className="profile-image-circle-right">
                        <div className="form-group mb-0">
                          <ul className="row">
                            <li className="col-xl-3 col-md-6 margin-unset">
                              <span className="d-block">Name</span>
                              {selectModule.name}
                            </li>
                            <li className="col-xl-3 col-md-6 margin-unset">
                              <span className="d-block"> Username</span>
                              {selectModule.name}
                            </li>
                            <li className="col-xl-3 col-md-6 margin-unset">
                              <span className="d-block"> Email</span>
                              {selectModule.emailAddress}
                            </li>
                            <li className="col-xl-3 col-md-6 margin-unset">
                              <span className="d-block">Department</span>
                              {selectModule.department}
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <p className="moduleReportHeading">
              {module.moduleReport?.moduleReport?.[0]?.moduleName}
            </p>
            <Tabs
              id="controlled-tab-example"
              activeKey={key}
              onSelect={(k) => setKey(k)}
              className="mb-3 nav-pills nav-fill"
            >
              <Tab eventKey="assessment" title="Assessment">
                <div className="row">
                  <div className="col-xl-12">
                    <div className="modular-table big-table table-responsive">
                      <div className="table-container">
                        <table className="table align-middle-modify">
                          <thead>
                            <tr>
                              <th scope="col">Module Name</th>
                              <th scope="col" className="text-center">
                                Date / Time
                              </th>
                              <th scope="col" className="text-center">
                                Duration
                              </th>
                              <th scope="col" className="text-center">
                                Mode
                              </th>
                              <th scope="col" className="text-center">
                                Score
                              </th>
                              <th scope="col" className="text-center">
                                Debriefing
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {module.moduleReport?.competencyReport?.map(
                              (item) => (
                                <tr key={item._id}>
                                  <td>{item.competencyName}</td>
                                  <td className="text-center">
                                    {new Date(
                                      item.updatedAt
                                    ).toLocaleDateString() +
                                      " - " +
                                      new Date(
                                        item?.updatedAt
                                      ).toLocaleTimeString()}
                                  </td>
                                  <td className="text-center">
                                    {getDurationString(item.duration)}
                                  </td>

                                  <td
                                    className="text-center"
                                    style={{ textTransform: "capitalize" }}
                                  >
                                    {item.mode ? item.mode : "N/A"}
                                  </td>

                                  <td className="text-center">
                                    {(
                                      item.averageCompetencyScore * 100
                                    ).toFixed(0)}
                                    %
                                  </td>

                                  <td className="text-center">
                                    <Link
                                      to={`/debriefing?moduleId=${
                                        item?.moduleId
                                      }&sessionId=${
                                        item?.sessionId
                                      }&competencyName=${
                                        item?.competencyName
                                      }&mode=assessment&score=${(
                                        item.averageCompetencyScore * 100
                                      ).toFixed(0)}`}
                                    >
                                      <span
                                        type="submit"
                                        className="primary_link p-0"
                                      >
                                        <BsEyeFill />
                                      </span>
                                    </Link>
                                  </td>
                                </tr>
                              )
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row mt-4">
                  <div className="col-xl-12">
                    <div className="modular-table big-table table-responsive p-4">
                      <div className="sec2-header">
                        <div className="row mb-5">
                          <div className="col-md-8">
                            <h2>Performance Analysis </h2>
                          </div>
                          <div
                            className="col-md-4"
                            style={{ textAlign: "right" }}
                          >
                            <CSVLink
                              data={csvReportData}
                              target="_blank"
                              filename={"report.csv"}
                            >
                              <button type="button" className="adcm-btn export">
                                Export
                              </button>
                            </CSVLink>
                          </div>
                        </div>
                        <div className="row mb-5">
                          <div className="col-md-4">
                            <div className="dropdown ">
                              <span
                                className="dropdown-toggle-change me-2 mb-2 d-inline-block"
                                id="dropdownMenuButton12"
                              >
                                Select Competencies
                              </span>
                              <select
                                onChange={(e) =>
                                  setSelectedCompetencyName(e.target.value)
                                }
                                value={selectedCompetencyName}
                              >
                                {options}
                              </select>
                            </div>
                          </div>
                          <div className="col-md-3">
                            <div className="form-group">
                              <label className="mb-2 d-inline-block">
                                From Date
                              </label>
                              <input
                                name="fromDate"
                                className="form-control"
                                component={renderInputField}
                                type="date"
                                label="From date"
                                onChange={(e) => setFromDate(e.target.value)}
                                max={moment().format("YYYY-MM-DD")}
                              />
                            </div>
                          </div>
                          <div className="col-md-3">
                            <div className="form-group">
                              <label className="mb-2 d-inline-block">
                                To Date
                              </label>
                              <input
                                name="toDate"
                                className="form-control"
                                component={renderInputField}
                                type="date"
                                label="To date"
                                data-date-format="MM DD YYYY"
                                onChange={(e) => setToDate(e.target.value)}
                                max={moment().format("YYYY-MM-DD")}
                              />
                            </div>
                          </div>
                          <div className="col-md-2 mt-auto">
                            <button
                              style={{ height: "45px" }}
                              className="adcm-btn search"
                              onClick={handleSearch}
                            >
                              Search
                            </button>
                          </div>
                        </div>
                      </div>
                      <div className="custom-barwrapper">
                        <ResponsiveContainer width="100%" height={300}>
                          <BarChart
                            width={700}
                            height={300}
                            data={selectedCompetency}
                            margin={{
                              top: 5,
                              right: 30,
                              left: 20,
                              bottom: 5,
                            }}
                            backgroundColor="#f9e6a6"
                          >
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis
                              dataKey="createdAt"
                              tickFormatter={(timestamp) =>
                                new Date(timestamp).toLocaleDateString()
                              }
                            />
                            <YAxis
                              type="number"
                              domain={[0, 100]}
                              axisLine={false}
                            />
                            <Tooltip
                              cursor={{ fill: "transparent" }}
                              content={({ payload, label, active }) => {
                                if (active && payload && payload.length) {
                                  const data = payload[0].payload;
                                  return (
                                    <div className="custom-tooltip">
                                      <p>{data.competencyName}</p>
                                      <p>
                                        Score:
                                        {(
                                          data.averageCompetencyScore * 100
                                        ).toFixed(0)}
                                        %
                                      </p>
                                      <p>
                                        Duration:
                                        {getDurationString(data.duration)}
                                      </p>
                                      <p>
                                        No of attempts:
                                        {data.competencyAttempts}
                                      </p>
                                    </div>
                                  );
                                }
                                return null;
                              }}
                            />
                            <defs>
                              <linearGradient
                                id="colorGradient"
                                x1="0"
                                y1="0"
                                x2="0"
                                y2="1"
                              >
                                <stop
                                  offset="0%"
                                  stopColor="#4840c0"
                                  stopOpacity={0.9}
                                />
                                <stop
                                  offset="100%"
                                  stopColor="#2d2e6f"
                                  stopOpacity={1}
                                />
                              </linearGradient>
                            </defs>
                            <Bar
                              dataKey={(data) =>
                                data.averageCompetencyScore * 100
                              }
                              value="kk"
                              fill="url(#colorGradient)"
                              barSize={maxBarwidth}
                            />
                          </BarChart>
                        </ResponsiveContainer>
                      </div>
                    </div>
                  </div>
                </div>
              </Tab>

              <Tab eventKey="training" title="Training">
                <div className="row">
                  <div className="col-xl-12">
                    <div className="modular-table big-table table-responsive">
                      <table className="table align-middle-modify">
                        <thead>
                          <tr>
                            <th scope="col">Module Name</th>
                            <th scope="col" className="text-center">
                              Date / Time
                            </th>
                            <th scope="col" className="text-center">
                              Mode
                            </th>
                            <th scope="col" className="text-center">
                              Debriefing
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {module.moduleTrainingReport?.moduleReport.map(
                            (item, index) => (
                              <tr key={index}>
                                <td>{item?.competencyName}</td>
                                <td className="text-center">
                                  {new Date(
                                    item.eventTime
                                  ).toLocaleDateString() +
                                    " - " +
                                    new Date(
                                      item?.eventTime
                                    ).toLocaleTimeString()}
                                </td>

                                <td
                                  className="text-center"
                                  style={{ textTransform: "capitalize" }}
                                >
                                  {"Training"}
                                </td>

                                <td className="text-center">
                                  <Link
                                    to={`/debriefing?moduleId=${item?.moduleId}&sessionId=${item?.sessionID}&competencyName=${item?.competencyName}&mode=training`}
                                    onClick={() =>
                                      DeberifingData(
                                        item.moduleId,
                                        item.sessionId
                                      )
                                    }
                                  >
                                    <span
                                      type="submit"
                                      className="primary_link p-0"
                                    >
                                      <BsEyeFill />
                                    </span>
                                  </Link>
                                </td>
                              </tr>
                            )
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </Tab>
            </Tabs>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  user: state.auth.user,
  initialValues: state.users.selectedUser,
  token: state.auth.token,
  module: state.modules,
});

const mapDispatchToProps = (dispatch) => {
  return {
    moduleActions: bindActionCreators(moduleActions, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ModuleReport);
