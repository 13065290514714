import React, { useEffect, useState } from "react";
import { getValueByName } from "../../../common/utilityFunctions";
import * as userdashboardActions from "../../../action/userdashboardActions";
import { Link, useHistory } from "react-router-dom";
import arrowdown from "../../../images/arrow-down-left.svg";
import EldcLogo from "../../../images/MedVR_Logo.png";
import * as moduleActions from "../../../action/moduleActions";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as userActions from "../../../action/userActions";
import { get } from "lodash";
import { loadState } from "../../../store/LocalStorage";

import UserTable from "./UserTable";
import MobileNavBar from "../../../components/mobileNavBar";
// import { toast } from 'react-toastify';

const YoureModuleDetail = (props) => {
  const { handleSubmit, allModulesByCompany, token, user } = props;
  const [selectedModules, setSelectedModules] = useState([]);
  const selectModel = localStorage.getItem("selectedModule");
  const [usersMobileNos, setUsersMobileNos] = useState([]);

  function removeDuplicates(arr) {
    return [...new Set(arr)];
  }

  const [selectModule, setSelectModule] = useState({});

  useEffect(() => {
    const updatedModule = Object.assign(
      {},
      selectModel === null ? undefined : JSON.parse(selectModel)
    );
    console.log(1, updatedModule.modules);

    updatedModule.modules = removeDuplicates(updatedModule.modules);
    console.log(2, removeDuplicates(updatedModule.modules));
    setSelectModule(updatedModule);
  }, []);

  const { loggedIn, modules } = get(loadState(), "auth") || {};
  let auth = {
    token: token || "",
    loggedIn: loggedIn || false,
    modules: modules || [],
    user: {
      ...user,
    },
  };
  const [moduleList, setmoduleList] = useState(auth.modules);

  if (moduleList && selectModule.modules) {
    for (let i = 0; i < selectModule.modules.length; i++) {
      for (let j = 0; j < moduleList.length; j++) {
        if (selectModule.modules[i]?.moduleId === moduleList[j]?._id) {
          selectModule.modules[i].moduleId = moduleList[j];
        }
      }
    }
  }
  let [averageScore, setAverageScore] = useState(null);
  // const [allModules, setAllModules] = useState([]);
  let [modulesStatus, setModulesStatus] = useState(null);
  let [searchTerm, setsearchTerm] = useState("");
  const [data, setData] = useState([]);

  useEffect(() => {
    props.moduleActions.getAllModulesByCompany(token, user.companyId);
    setSelectedModules(
      selectModule.modules && allModulesByCompany
        ? selectModule.modules.map(
            (assignedModule) =>
              allModulesByCompany.filter(
                (module) => module._id === assignedModule.moduleId
              )[0]
          )
        : []
    );
    userActions
      .getUsersMobileNo()
      .then((res) => setUsersMobileNos(res.data || []));
  }, []);

  useEffect(() => {
    loadUserAverageScore();
    loadUserModulesStatus();

    props.moduleActions.getModuleReport(props.match.params.id, user._id);
  }, []);

  const loadUserAverageScore = () => {
    userdashboardActions
      .getUserAverageScore()
      .then((response) => setAverageScore(response.data.score))
      .catch((error) =>
        console.error(
          `failed to get user Average Score  ${error.response.data.message}`
        )
      );
  };
  const loadUserModulesStatus = () => {
    userdashboardActions
      .getUserModuleStatus()
      .then((response) => setModulesStatus(response.data))
      .catch((error) =>
        console.error(
          `failed to get user modules data  ${error.response.data.message}`
        )
      );
  };

  const keys = ["moduleName"];
  const search = (data) => {
    return data.filter((item) =>
      keys.some((key) => item[key]?.toLowerCase().includes(searchTerm))
    );
  };

  const getDurationString = (d) => {
    let x = d.toString().split(".");

    x[0] = parseInt(x[0]);
    x[1] = parseInt(x[1].slice(0, 2)) / 100;

    let durationString = x[0] + " hrs " + (x[1] * 60).toFixed(0) + " mins";
    return durationString;
  };
  return (
    <>
      <div className="overview-cont">
        <div className="row">
          <div className="col-xl-12 col-lg-12 overview-left-area your-module">
            <MobileNavBar />
            <div className="d-flex justify-content-between align-items-center">
              <h2 className="right_hading d-none d-md-inline">Module Detail</h2>
            </div>
            <h2 className="right_hading d-md-none d-block">Module Library</h2>

            <div className="row">
              <div className="col-xl-12">
                <div className="modular-table table-responsive-xl">
                  <table className="table align-middle-modify">
                    <thead>
                      <tr>
                        <th scope="col">Program Name</th>
                        <th scope="col" className="text-center">
                          Assign Date
                        </th>
                        <th scope="col" className="text-center">
                          Score
                        </th>
                        <th scope="col" className="text-center">
                          Duration
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {props.module?.moduleReport?.moduleReport &&
                        props.module?.moduleReport?.moduleReport?.map(
                          (item) => (
                            <tr key={item._id}>
                              <td>{item?.moduleName}</td>
                              <td className="text-center">
                                {new Date(
                                  item?.moduleAssignTime
                                ).toLocaleDateString()}
                              </td>
                              <td className="text-center">
                                {(item.moduleAverageScore * 100).toFixed(0)} %
                              </td>
                              <td className="text-center">
                                {getDurationString(item.moduleDuration)}
                              </td>
                            </tr>
                          )
                        )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    user: state.auth.user,
    initialValues: state.users.selectedUser,
    token: state.auth.token,
    allModulesByCompany: state.modules.allModulesByCompany,
    module: state.modules,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    moduleActions: bindActionCreators(moduleActions, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(YoureModuleDetail);
