import { getInstance } from "./axiosFactory";
import { raiseError } from "./globalActions";
const axiosInstance = getInstance();
// import axios from "axios";

export function loadModuleReport(data) {
  return {
    type: "LOAD_MODULE_REPORT",
    payload: data,
  };
}

export function loadModuleTrainingReport(data) {
  return {
    type: "LOAD_MODULE_TRAINING_REPORT",
    payload: data,
  };
}

export function loadDefriefingReport(data) {
  return {
    type: "LOAD_DEBRIEFING_REPORT",
    payload: data,
  };
}

export function loadDefriefingTrainingReport(data) {
  return {
    type: "LOAD_DEBRIEFING_TRAINING_REPORT",
    payload: data,
  };
}

export const searchModules = (searchString) => {
  return axiosInstance.post("/module/searchmodules", {
    searchString: searchString,
  });
};

export function loadGlobalDataReport(data) {
  return {
    type: "LOAD_GLOBALDATA_REPORT",
    payload: data,
  };
}

export function loadModulesSuccess(modules) {
  return { type: "LOAD_MODULES", modules };
}
export function loadAllModulesByCompanySuccess(modules) {
  return { type: "LOAD_MODULES_BY_COMPANY", modules };
}
export function loadUserModulesSuccess(modules) {
  return { type: "LOAD_USER_MODULES", modules };
}

export function loadSelectedModule(selectedModule) {
  return { type: "MODULE_SELECTED", selectedModule };
}
export function loadSelectedModuleVersion(selectedModuleVersion) {
  return { type: "MODULE_VERSION_SELECTED", selectedModuleVersion };
}
export function loadSelectedModuleCompany(selectedModuleCompany) {
  return { type: "MODULE_COMPANY_SELECTED", selectedModuleCompany };
}

export const selectedModule = (selectedModule) => {
  return (dispatch) => {
    dispatch(loadSelectedModule(selectedModule));
  };
};

export const selectedModuleVersion = (selectedModuleVersion) => {
  return (dispatch) => {
    dispatch(loadSelectedModuleVersion(selectedModuleVersion));
  };
};

export const selectedModuleCompany = (selectedModuleCompany) => {
  return (dispatch) => {
    dispatch(loadSelectedModuleCompany(selectedModuleCompany));
  };
};
export const addModule = (token, moduleData) => {
  return axiosInstance.post("/module/addmodule", moduleData, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
};

export const modifyModule = (token, data) => {
  return axiosInstance.post("/module/modifymodule", data, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
};

export const addVersion = (token, data) => {
  return axiosInstance.post("/module/addversion", data, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
};

export const modifyVersion = (token, data) => {
  return axiosInstance.post("/module/modifyversion", data, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
};

export const addAssignCompany = (token, data) => {
  return axiosInstance.post("/module/addassigncompany", data, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
};

export const getAllModules = (token, pageNumber, pageSize) => {
  return (dispatch) => {
    axiosInstance
      .post(
        "/module/getallmodules",
        { pageSize, pageNumber },
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      )
      .then((res) => dispatch(loadModulesSuccess(res.data.modules)))
      .catch((err) => dispatch(raiseError(err)));
  };
};

export const fetchModules = (pageNumber, pageSize, sortOrder, sortColumn) => {
  return axiosInstance.post("/module/getallmodules", {
    pageSize,
    pageNumber,
    sortOrder,
    sortColumn,
  });
};

export const validateImportModuleFile = (
  token,
  file,
  companyId,
  userShouldChangePasswordAtFirstLogin
) => {
  let formData = new FormData();
  formData.append("fileName", file);
  formData.append(
    "userShouldChangePasswordAtFirstLogin",
    userShouldChangePasswordAtFirstLogin
  );
  formData.append("companyId", companyId);
  return axiosInstance.post("/account/validateFile", formData, {
    headers: {
      "Content-Type": "multipart/form-data",
      authorization: "Bearer " + token,
    },
  });
};

export const processImportModulesFile = (token, fileId) => {
  return axiosInstance.post(
    "/account/importfile",
    { fileId: fileId },
    {
      headers: {
        Authorization: "Bearer " + token,
      },
    }
  );
};

export const getUserModules = (token) => {
  return (dispatch) => {
    fetchUserModules(token)
      .then((res) => dispatch(loadUserModulesSuccess(res.data)))
      .catch((err) => dispatch(raiseError(err)));
  };
};

export const globalDashboardData = () => {
  return (dispatch) => {
    axiosInstance
      .post("/admindashboard/statistics", { filter_type: "All" })
      .then((data) => {
        dispatch(loadGlobalDataReport(data));
      })
      .catch((err) => dispatch(raiseError(err)));
  };
};

export const fetchUserModules = (token) => {
  return axiosInstance.get("/module/getusermodules", {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
};

export const modifyAssignCompany = (token, data) => {
  return axiosInstance.post("/module/modifyassigncompany", data, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
};

export const getAllModulesByCompany = (token, companyId) => {
  return (dispatch) => {
    axiosInstance
      .post(
        "/module/getallmodulesbycompany",
        { companyId },
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      )
      .then((res) => dispatch(loadAllModulesByCompanySuccess(res.data)))
      .catch((err) => dispatch(raiseError(err)));
  };
};
export const fetchAllModulesByCompany = () => {
  return axiosInstance.post("/module/getallmodulesbycompany", {});
};

// export const fetchAllModulesByManager = () => {
//   return axiosInstance.post("/module/get-module-by-manager", {});
// };

export const assignModulesToAllCompanyUsers = (modules) => {
  return axiosInstance.post("/module/assignmodulestoallCompanyusers", {
    modules: modules,
  });
};

export const getModuleCompetencies = (moduleid) => {
  return axiosInstance.get(
    `/module/getmodulecompetencies?moduleid=${moduleid}`
  );
};

export const getUserDetailbyUserid = (user_id) => {
  return axiosInstance.get(
    `/account/user-details?userId=${user_id}`
  );
};

export const fetchAllModulesByManager = (MangerID) => {
  return axiosInstance.get(`/module/get-module-by-manager/${MangerID}`);
};

export const getModuleReport = (moduleId, userId) => {
  return (dispatch) => {
    axiosInstance
      .get(
        `/module/getcompetecyaveragedetails?${
          moduleId !== "" ? "moduleId=" + moduleId : ""
        }&userId=${userId}`
      )
      .then((data) => dispatch(loadModuleReport(data)))
      .catch((err) => dispatch(raiseError(err)));
  };
};

export const getModuleTrainingReport = (moduleId, userId) => {
  return (dispatch) => {
    axiosInstance
      .get(
        `/module/gettrainingaveragedetails?${
          moduleId !== "" ? "moduleId=" + moduleId : ""
        }&userId=${userId}`
      )
      .then((data) => dispatch(loadModuleTrainingReport(data)))
      .catch((err) => dispatch(raiseError(err)));
  };
};

export const getDebriefingDataLD = (moduleid, sessionId, campName) => {
  return (dispatch) => {
    axiosInstance
      .get(
        `/module/getComeptencyDebriefingDetails?moduleId=${moduleid}&sessionId=${sessionId}&competencyName=${campName}`
      )
      .then((data) => {
        dispatch(loadDefriefingReport(data));
      })
      .catch((err) => dispatch(raiseError(err)));
  };
};

export const getDebriefingTrainingData = (moduleid, sessionId, campName) => {
  return (dispatch) => {
    axiosInstance
      .get(
        `/module/gettrainingdebriefingdetails?moduleId=${moduleid}&sessionId=${sessionId}&competencyName=${campName}`
      )
      .then((data) => {
        dispatch(loadDefriefingTrainingReport(data));
      })
      .catch((err) => dispatch(raiseError(err)));
  };
};

// export const getDebriefingData = (moduleid, sessionId,campName) => {
//   return axiosInstance.get(
//     `/module/getComeptencyDebriefingDetails?moduleId=${moduleid}&sessionId=${sessionId
//     }&competencyName=${campName}`)
// };

export const getBulkReport = async (data) => {
  return await axiosInstance.post("/module/bulk-report", data);
};

export const getStatisticsReport = async (data) => {
  return await axiosInstance.post("/admindashboard/statistics", data);
};

export const getModuleByUser = async () => {
  return await axiosInstance.get("/module/getModuleByUser");
};
