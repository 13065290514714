import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import * as moduleActions from "../../../action/moduleActions";
import { reduxForm, Field, initialize } from "redux-form";
import {
  renderInputField,
  renderTextAreaField,
  renderRadioButtonField,
  RenderImageField,
  renderCheckBoxField,
  RenderAwsFileField,
  renderSelect,
} from "../../../common/InputFields";
import { get } from "lodash";
import { useHistory } from "react-router-dom";
import Ellipse9 from "../../../images/Ellipse9.png";
import linkBg from "../../../images/link.svg";
import EldcLogo from "../../../images/MedVR_Logo.png";
import Background from "../../../images/company-profile-bg.jpg";
import plus from "../../../images/plus.svg";
import * as userActions from "../../../action/userActions";
import Swal from "sweetalert2";
import MobileNavBar from "../../../components/mobileNavBar";

let ModifyModulepage = (props) => {
  const { handleSubmit, module, auth, token, modifymodule } = props;
  const history = useHistory();
  const [competencies, setCompetencies] = useState(
    module ? [...module.competencies] : []
  );
  const [errorBorderCss, setErrorBorderCss] = useState(null);
  const [HomePage, setHomepage] = useState(Background);
  const [platformsErrCss, setPlatformsErrCss] = useState(null);

  useEffect(() => {
    props.dispatch(
      initialize("modifymodule", {
        ...module,
        Windows: module.platforms.includes("Windows"),
        Oculus: module.platforms.includes("Oculus"),
        HTC: module.platforms.includes("HTC"),
        PICO: module.platforms.includes("PICO"),
        status: `${module.status}`,
      })
    );
    setHomepage(module.bannerImage);
  }, []);

  const modifyModuleData = (values = {}) => {
    modifymodule.platforms = [];
    if (modifymodule.Windows) modifymodule.platforms.push("Windows");
    if (modifymodule.Oculus) modifymodule.platforms.push("Oculus");
    if (modifymodule.HTC) modifymodule.platforms.push("HTC");
    if (modifymodule.PICO) modifymodule.platforms.push("PICO");
    modifymodule.competencies = [...competencies];

    let data = modifymodule;

    moduleActions
      .modifyModule(auth, data)
      .then(() => {
        Swal.fire({
          title: "Success",
          text: "Module  Modified Successfully",
          icon: "success",
          confirmButtonText: "Ok",
          allowOutsideClick: false,
        }).then(function () {
          history.push("/module");
        });
      })
      .catch((error) => toast(error.message));
  };

  const addCompetency = () => {
    if (
      modifymodule.competency &&
      modifymodule.competency.trim() &&
      !competencies.includes(modifymodule.competency)
    ) {
      const newCompetencies = [...competencies, modifymodule.competency];
      props.change("competency", "");
      setCompetencies([...newCompetencies]);
    } else if (competencies.includes(modifymodule.competency)) {
      toast("Duplicate competency");
    }
  };
  const onCompetencyClick = (selectedCompetency) => {
    const newCompetencies = competencies.filter(
      (item) => item !== selectedCompetency
    );
    props.dispatch(
      initialize("modifymodule", {
        ...modifymodule,
        competency: selectedCompetency,
      })
    );
    setCompetencies([...newCompetencies]);
  };
  const onDeleteCompetencyClick = (e, selectedCompetency) => {
    e.stopPropagation();
    const newCompetencies = competencies.filter(
      (item) => item !== selectedCompetency
    );
    setCompetencies([...newCompetencies]);
  };

  const onModuleType = (error) => {
    setErrorBorderCss(error ? "error-border" : null);
  };
  const onPlatformsError = (error) => {
    setPlatformsErrCss(error ? "error-border" : null);
  };

  var modifyBackgroundStyle = {
    width: "100%",
    backgroundImage: `url(${get(HomePage, "Location") || Background})`,
  };

  const onUploadHome = (event) => {
    userActions.uploadFile(token, get(event, "target.files[0]")).then((res) => {
      setHomepage(res);

      modifymodule.bannerImage = res;
    });
  };

  return (
    <div className="overview-cont">
      <div className="row">
        <div className="col-xl-12 col-lg-12 overview-left-area your-module">
          <form onSubmit={handleSubmit(modifyModuleData)}>
            <MobileNavBar />
            <div class="d-flex align-items-center justify-content-between">
              <h2 class="right_hading d-none d-sm-inline">Modify Module</h2>

              <div class="mt-3">
                <a href="module" class="adcm-btn Cancel ms-2">
                  {" "}
                  Cancel
                </a>
                {/* <a href="#" class="adcm-btn ms-2"> Add</a> */}
                <a>
                  <button type="submit" className="adcm-btn ms-1">
                    <img src={plus} style={{ width: "10px" }} alt=" " />
                    Modify
                  </button>
                </a>
              </div>
            </div>
            <h2 class="right_hading d-sm-none d-block">Modify Module</h2>

            <div class="row">
              <div class="col-lg-12">
                <div class="company-main add-module">
                  <div class="company-profile" style={modifyBackgroundStyle}>
                    {/* <a href="#" class="profile-changer"> <img class="me-1" src="assets/images/link.svg" alt="" /> Change Image</a> */}
                    <label className="profile-changer" for="nhg">
                      <img className="me-1" src={linkBg} alt="" /> Change Image
                    </label>
                    <input
                      onChange={onUploadHome}
                      accept={".jpg, .jpeg, .png"}
                      type="file"
                      style={{
                        opacity: 0,
                        width: "100%",
                        position: "absolute",
                      }}
                      id="nhg"
                    ></input>
                  </div>
                  <div class="company-fillout">
                    <h3 class="fill-heaing">
                      Module Information (Module Id - {modifymodule?._id})
                    </h3>
                    <div class="row">
                      <div class="col-md-6">
                        <div class="form-group">
                          <label>Module Name</label>
                          {/* <input type="text" class="form-control" placeholder="ABC" autocomplete="off" /> */}
                          <Field
                            name="moduleName"
                            component={renderInputField}
                            type="text"
                            label="Module name"
                          />
                        </div>
                        <div class="form-group mb-5">
                          <div class="custom-file">
                            <label>Module Guide</label>
                            {/* <input type="file" class="custom-file-input" id="validatedCustomFile" required /> */}
                            {/* <input onChange={onUploadSigned} accept={'.jpg, .jpeg, .png'} type="file"
                                                                style={{ opacity: 0, width: '100%', position: 'absolute' }}

                                                            ></input>
                                                            <span class="custom-file-label" for="validatedCustomFile">Choose file...</span> */}
                            <Field
                              name="moduleGuide"
                              token={auth ? token : ""}
                              component={RenderAwsFileField}
                              uploadLabel="Browse"
                              isPlaceHolderImage={false}
                              className="mr"
                              fileType=".pdf"
                            />
                          </div>
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-group">
                          <label>Module Demo Link</label>
                          {/* <input type="text" class="form-control" placeholder="https://www.abc.co" autocomplete="off" /> */}
                          <Field
                            name="moduleDemoLink"
                            component={renderInputField}
                            type="text"
                            label="Module demo link"
                          />
                        </div>
                        <div class="form-group">
                          <label>Product Code</label>
                          <Field
                            name="product_code"
                            component={renderInputField}
                            type="text"
                            label="Product Code"
                          />
                          {/* <Field
                              name="moduleDemoLink"
                              component={renderInputField}
                              type="text"
                              label="Module demo link"
                            /> */}
                        </div>
                      </div>
                      <div class="col-md-12">
                        <div class="form-group">
                          <label>Module Description</label>
                          {/* <textarea class="form-control" id="exampleFormControlTextarea1" placeholder="This module is...."></textarea> */}
                          <Field
                            name="moduleDescription"
                            component={renderTextAreaField}
                            type="textarea"
                            label="This module is...."
                          />
                        </div>
                      </div>

                      <div class="col-md-12">
                        <h3 class="fill-heaing">Additional Information</h3>
                        <div class="row">
                          <div class="col-md-6">
                            <div className="form-group">
                              <label>Industry</label>
                              <Field
                                name="industry"
                                component={renderInputField}
                                type="text"
                                label="Industry"
                              />
                            </div>
                            <div className="form-group">
                              <label>Module Type</label>
                              <div
                                className={`d-flex flex-column ${errorBorderCss}`}
                              >
                                <Field
                                  name="moduleType"
                                  component={renderSelect}
                                  onError={onModuleType}
                                >
                                  <option>Please Select</option>
                                  <option value="Unity">Unity</option>
                                  <option value="Unreal Engine">
                                    Unreal Engine
                                  </option>
                                  <option value="Other">Other</option>
                                </Field>
                                {/* <div className="radioGroupItem mr-4">
                            <label htmlFor="UserManager">Unity</label>
                            <Field
                              component={renderRadioButtonField}
                              name="moduleType"
                              value="Unity"
                              className="ml-4"
                              type="radio"
                              label="Select"
                              onError={onModuleType}
                            />
                          </div>
                          <div className="radioGroupItem">
                            <label htmlFor="UserAdmin">Unreal Engine</label>
                            <Field
                              component={renderRadioButtonField}
                              name="moduleType"
                              value="Unreal Engine"
                              className="ml-4"
                              type="radio"
                              label="Select"
                            />
                          </div>
                          <div className="radioGroupItem">
                            <label htmlFor="UserEmployee">Other</label>
                            <Field
                              component={renderRadioButtonField}
                              name="moduleType"
                              value="Other"
                              className="ml-4"
                              type="radio"
                              label="Select"
                            />
                          </div> */}
                              </div>
                            </div>

                            <div className="form-group">
                              <label>Status</label>
                              <div
                                className={`d-flex flex-column ${errorBorderCss}`}
                              >
                                <Field
                                  name="status"
                                  component={renderSelect}
                                  onError={onModuleType}
                                >
                                  <option>Please Select</option>
                                  <option value="true">Active</option>
                                  <option value="false">Inactive</option>
                                </Field>
                              </div>
                            </div>
                          </div>

                          <div class="col-md-6 ">
                            <div className="form-group">
                              <label>Modules Platform</label>
                              <div className="d-flex radioGroup verticleCardradio flex-column">
                                <div className="radioGroupItem mr-4">
                                  <label htmlFor="UserManager">Windows</label>
                                  <Field
                                    component={renderCheckBoxField}
                                    name="Windows"
                                    value="Windows"
                                    type="checkbox"
                                    onError={onPlatformsError}
                                    className="checkCss"
                                  />
                                </div>
                                <div className="radioGroupItem">
                                  <label htmlFor="UserAdmin">Oculus</label>
                                  <Field
                                    component={renderCheckBoxField}
                                    name="Oculus"
                                    value="Oculus"
                                    type="checkbox"
                                  />
                                </div>
                                <div className="radioGroupItem">
                                  <label htmlFor="UserEmployee">HTC</label>
                                  <Field
                                    component={renderCheckBoxField}
                                    name="HTC"
                                    value="HTC"
                                    type="checkbox"
                                  />
                                </div>
                                <div className="radioGroupItem">
                                  <label htmlFor="UserEmployee">PICO</label>
                                  <Field
                                    component={renderCheckBoxField}
                                    name="PICO"
                                    value="PICO"
                                    type="checkbox"
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="form-group">
                              <label>Competencies</label>
                              <div className="InputTextInBttn">
                                <div className="form-group">
                                  <Field
                                    name="competency"
                                    component={renderInputField}
                                    type="text"
                                    label="competencies..."
                                    validate={[
                                      () =>
                                        competencies && competencies.length > 0
                                          ? undefined
                                          : "Required",
                                    ]}
                                  />
                                </div>
                                <div className="form-group inputINbttn">
                                  <button
                                    type="button"
                                    onClick={addCompetency}
                                    className="btn middiumBttn themesecondarybackground"
                                  >
                                    Add
                                  </button>
                                </div>
                              </div>
                              {competencies && competencies.length > 0 && (
                                <div className="compitencyResult">
                                  {competencies.map((item, index) => (
                                    <div
                                      key={`${item}-${index}`}
                                      className="moduleCompetencyCard"
                                      onClick={() => onCompetencyClick(item)}
                                    >
                                      <label htmlFor="UserManager">
                                        {item}
                                      </label>
                                      <button
                                        onClick={(e) =>
                                          onDeleteCompetencyClick(e, item)
                                        }
                                      >
                                        X
                                      </button>
                                    </div>
                                  ))}
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

ModifyModulepage = reduxForm({
  form: "modifymodule",
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
})(ModifyModulepage);

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    module: state.modules.selectedModule,
    modifymodule: state.form.modifymodule
      ? state.form.modifymodule.values
      : null,
  };
};

export default connect(mapStateToProps)(ModifyModulepage);
