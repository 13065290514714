import { getInstance } from "./axiosFactory";

const axiosInstance = getInstance();

export const getCoursesOverview = () => {

  return axiosInstance.get("/admindashboard/getCoursesOverview");
 
};

export const getActiveUsersList = () => {
  
    return axiosInstance.get("/admindashboard/getActiveUserslist");
   
  };

export const getCompanyKpis = () => {

    return axiosInstance.get("/admindashboard/getCompanyKpis");
   
  };

export const getActiveSessionsByMonth = () => {
 
    return axiosInstance.get("/admindashboard/getActiveSessionsByMonth")
   
  };
  