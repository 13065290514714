import { getInstance } from "./axiosFactory";
import { raiseError } from "./globalActions";
import { loginSuccess } from "./authActions";
import axios from "axios";
const axiosInstance = getInstance();

export function loadUsersSuccess(users) {
  return { type: "LOAD_USERS", users };
}

export const loadAllUsersByCompanySuccess = (users) => {
  return { type: "LOAD_USERS_BY_COMPANY", users };
};

export function loadSelectedUser(selectedUser) {
  return { type: "USER_SELECTED", selectedUser };
}

export const selectedUser = (selectedUser) => {
  loadSelectedUser(selectedUser);
  return (dispatch) => {
    dispatch(loadSelectedUser(selectedUser));
  };
};

export const deleteUser = (dalId) => {
  // return axiosInstance.delete("/account/deleteProfile");
  return axiosInstance.delete(`/account/deleteProfile?userId=${dalId}`);
};

export const getDebriefingData = (moduleid, sessionId, campName) => {
  return axiosInstance.get(
    `/module/getComeptencyDebriefingDetails?moduleId=${moduleid}&sessionId=${sessionId}&competencyName=${campName}`
  );
};

export const getDebriefingTrainingData = (moduleid, sessionId, campName) => {
  return axiosInstance.get(
    `/module/gettrainingdebriefingdetails?moduleId=${moduleid}&sessionId=${sessionId}&competencyName=${campName}`
  );
};

export const addUser = (userData, token) => {
  return axiosInstance.post("/account/register", userData, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
};

export const modifyUser = (token, user) => {
  //serviceArea = JSON.stringify(serviceArea);
  return axiosInstance.post("/account/modifyuser", user, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
};

export const getAllUsers = (pageNumber, pageSize) => {
  return (dispatch) => {
    axiosInstance
      .post("/account/getusers", { pageSize: pageSize, pageNumber: pageNumber })
      .then((res) => dispatch(loadUsersSuccess(res.data.users)))
      .catch((err) => dispatch(raiseError(err)));
  };
};

export const fetchUsers = (
  pageNumber,
  pageSize,
  sortOrder,
  sortColumn,
  loginUserId
) => {
  return axiosInstance.post("/account/getusers", {
    pageSize: pageSize,
    pageNumber: pageNumber,
    sortOrder: sortOrder,
    sortColumn,
    loginUserId,
  });
};

// export const searchUsers = (searchString,lmt,off) => {
//   return axiosInstance.post(`/account/searchusers?limit=${lmt}&offset=${off}`, {
//     searchString: searchString,
//   });
// };
export const searchUsers = (searchString) => {
  return axiosInstance.post("/account/searchusers", {
    searchString: searchString,
  });
};

export const validateImportUserFile = (
  token,
  file,
  companyId,
  userShouldChangePasswordAtFirstLogin
) => {
  let formData = new FormData();
  formData.append("fileName", file);
  formData.append(
    "userShouldChangePasswordAtFirstLogin",
    userShouldChangePasswordAtFirstLogin
  );
  formData.append("companyId", companyId);
  return axiosInstance.post("/account/validateFile", formData, {
    headers: {
      "Content-Type": "multipart/form-data",
      authorization: "Bearer " + token,
    },
  });
};

export const processImportUsersFile = (token, fileId) => {
  return axiosInstance.post(
    "/account/importfile",
    { fileId: fileId },
    {
      headers: {
        Authorization: "Bearer " + token,
      },
    }
  );
};

export const changeUserPassword = (token, data) => {
  //serviceArea = JSON.stringify(serviceArea);
  return axiosInstance.post("/account/changepassword", data, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
};

export const updateUserProfile = (token, data) => {
  return axiosInstance.post("/account/updateProfile", data, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
};

export const uploadFile = (token, file) => {
  let { name, type } = file;
  let formData = new FormData();
  formData.append("image", file);
  return new Promise(function (resolve, reject) {
    axiosInstance
      .post("/file/uploadfile", formData, {
        headers: {
          "Content-Type": "image/*",
          authorization: "Bearer " + token,
        },
      })
      .then((response) => {
        resolve(response.data);
      })
      .catch((err) => reject(err));
  });
};

export const uploadSignedUrl = (token, file) => {
  let { name, type } = file;

  function uploadFile(file, signedRequest) {
    return axios.put(signedRequest, file, {
      onUploadProgress: function (progressEvent) {
        let progress = (progressEvent.loaded / progressEvent.total) * 100;
        // setProgress(progress.toPrecision(3));
      },
    });
  }

  let loadata = { fileName: name, fileType: type };
  // let formData = new FormData();
  // formData.append('image', file);
  return new Promise(function (resolve, reject) {
    axiosInstance
      .post("/file/getUploadSignedUrl", loadata, {
        headers: {
          "Content-Type": "image/*",
          authorization: "Bearer " + token,
        },
      })
      .then((response) => {
        let { signedRequest, url } = response.data;

        uploadFile(file, signedRequest)
          .then(function (result) {
            // onChange({ Location: url, fileSize: fileSize, key: name, Key: name });
            // setProgress(null);
            // onUploadSuccess(response.data.path);
            resolve(url);
          })
          .catch((err) => {
            reject(err);
          });

        //let image = { publicId: path.public_id, imageUrl: path.path }
      })
      .catch((err) => reject(err));
  });
};

export const updateUserProfilePic = (token, data) => {
  return axiosInstance.post("/account/updateProfilePic", data, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
};

export const getUpdatedUserDataSample = (token, data) => {
  return axiosInstance.post("/account/getUpdateUserData", data, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
};

export const getUpdatedUserData = (token, data) => {
  return (dispatch) => {
    axiosInstance
      .post("/account/getUpdateUserData", data, {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
      .then((res) => {
        localStorage.setItem("selectedModule", JSON.stringify(res.data));
        let x = JSON.parse(localStorage.getItem("state"));
        x.auth.user = res.data;
        localStorage.setItem("state", JSON.stringify(x));

        dispatch(loginSuccess({ token: token, user: res.data }));
      })
      .catch((err) => dispatch(raiseError(err)));
  };
};

export const sendInviteAction = (token, emailAddresses) => {
  return axiosInstance.post(
    "account/sendInvite",
    { emailAddresses },
    {
      headers: {
        Authorization: "Bearer " + token,
      },
    }
  );
};

export const getAllUsersByCompany = (token, companyId) => {
  return (dispatch) => {
    axiosInstance
      .post(
        "/account/getallusersbycompany",
        { companyId },
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      )
      .then((res) => dispatch(loadAllUsersByCompanySuccess(res.data)))
      .catch((err) => dispatch(raiseError(err)));
  };
};

export const assignModulesToUsers = (token, data) => {
  return axiosInstance.post("/account/assignmodules", data, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
};

export const assignModuleToUser = (token, data) => {
  return axiosInstance.post("/module/assign-module-to-user", data, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
};

export const getUsersMobileNo = () => {
  return axiosInstance.get("/account/getusersmobileno");
};

export const getRoles = () => {
  return axiosInstance.get("/userresponses/roles");
};

export const getCourses = (managerId) => {
  if (managerId) {
    return axiosInstance.get(
      `/account/getCourses${managerId && "?managerId=" + managerId}`
    );
  } else {
    return axiosInstance.get(`/account/getCourses`);
  }
};

export const getModules = async () => {
  return await axiosInstance.get(`/account/getModules`);
};
