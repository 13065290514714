import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import { createBrowserHistory, createMemoryHistory } from "history";
// import store from "./store";
import { get } from "lodash";
import { loadState } from "./store/LocalStorage";
import configureStore from "./store/configureStore";
const history = createBrowserHistory();
const { loggedIn, user, token, modules } = get(loadState(), "auth") || {};
let auth = {
  token: token || "",
  loggedIn: loggedIn || false,
  modules: modules || [],
  user: {
    ...user,
  },
};
const store = configureStore(
  { ...window.__PRELOADED_STATE__, auth: auth },
  history
);

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
