import initialState from './initialState';

export default function themeReducer(state = initialState.theme, action) {
  switch (action.type) {
    case 'CHANGE_THEME':
      return  action.theme 
    
    default:
      return state;
  }
}
