import React, { useEffect, useState } from "react";
import {
  getAllCompetenciesWithScore,
  getUserAverageScore,
  getUserModuleStatus,
} from "../../../action/userdashboardActions";
import { getValueByName } from "../../../common/utilityFunctions";
import * as moduleActions from "../../../action/moduleActions";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import arrowdown from "../../../images/arrow-down-left.svg";
import EldcLogo from "../../../images/MedVR_Logo.png";
import Search from "../../common/Search";
import SkillTableDetail from "./SkillTableDetail";

const SkillPerformanaceDetail = (props) => {
  const [allCompetencies, setAllCompetencies] = useState([]);
  let [modulesStatus, setModulesStatus] = useState(null);
  let [averageScore, setAverageScore] = useState(null);
  let [searchTerm, setsearchTerm] = useState("");
  let skill = props?.match?.params?.string;
  useEffect(() => {
    props.moduleActions.getModuleReport("", props.user._id);
  }, [props.user._id]);

  const loadUserAverageScore = () => {
    getUserAverageScore()
      .then((response) => setAverageScore(response.data.score))
      .catch((error) =>
        console.error(
          `failed to get user Average Score  ${error.response.data.message}`
        )
      );
  };

  const loadUserModulesStatus = () => {
    getUserModuleStatus()
      .then((response) => setModulesStatus(response.data))
      .catch((error) =>
        console.error(
          `failed to get user modules data  ${error.response.data.message}`
        )
      );
  };

  const keys = ["competencyName"];
  const search = (data) => {
    return data?.filter((item) =>
      keys.some((key) => item[key]?.toLowerCase().includes(searchTerm))
    );
  };

  var data = props.module?.moduleReport?.competencyReport;
  let result = data.reduce(function (r, a) {
    if (a.competencyName == skill) {
      r[a.competencyName] = r[a.competencyName] || [];
      r[a.competencyName].push(a);
    }
    return r;
  }, Object.create(null));

  return (
    <>
      <div className="overview-cont">
        <div className="row">
          <div className="col-xl-12 col-lg-12 overview-left-area your-module">
            <div className="d-flex justify-content-between align-items-center">
              <div className="sec1 logo d-block d-lg-none">
                <a href="index.html">
                  <img src={EldcLogo} alt="" />
                </a>
              </div>
              <button
                className="navbar-toggler"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#navbarResponsive"
                aria-controls="navbarResponsive"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span className="navbar-toggler-icon"></span>
              </button>
            </div>
            <div className="d-flex justify-content-between align-items-center">
              <h2 className="right_hading d-none d-md-inline">
                Skill Performance
              </h2>
              {/* <!-----button added from up section--> */}
              {/* <form action="" className="search">
                <input
                  type="text"
                  placeholder="search ..."
                  onChange={(event) => {
                    setsearchTerm(event.target.value.toLowerCase());
                  }}
                />
              </form> */}
            </div>
            <h2 className="right_hading d-md-none d-block">Module Library</h2>

            <div className="row">
              <div className="col-xxl-12 col-xl-12 col-lg-12">
                <div className="table-responsive modular-table">
                  <SkillTableDetail data={result} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    user: state.auth.user,
    token: state.auth.token,
    module: state.modules,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    moduleActions: bindActionCreators(moduleActions, dispatch),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SkillPerformanaceDetail);
