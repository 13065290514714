import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import EldcLogo from "../../../images/MedVR_Logo.png";
import { Link, useHistory } from "react-router-dom";
import Ellipse9 from "../../../images/Ellipse9.png";
import { get } from "lodash";
import { bindActionCreators } from "redux";
import * as userActions from "../../../action/userActions";
import * as moduleActions from "../../../action/moduleActions";

import Background from "../../../images/company-profile-bg.jpg";
import axios from "axios";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { useParams } from "react-router-dom";
import { getModuleReport } from "../../../action/moduleActions";
import MobileNavBar from "../../../components/mobileNavBar";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import { useLocation } from "react-router-dom";

function useQuery() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}

const DebriefingPage = (props) => {
  const { handleSubmit, auth, token, user, moduleActions, module } = props;
  const [key, setKey] = useState("home");
  let query = useQuery();

  const selectModel = localStorage.getItem("selectedModule");
  const [selectModule, setSelectModule] = useState(
    selectModel === null ? undefined : JSON.parse(selectModel)
  );

  let [loading, setLoading] = useState(true);

  const modid = localStorage.getItem("modid");
  useEffect(() => {
    setLoading(true);
    if (query.get("mode") == "training") {
      userActions
        .getDebriefingTrainingData(
          query.get("moduleId"),
          query.get("sessionId"),
          query.get("competencyName")
        )
        .then((responce) => {
          if (responce.data.status) {
            moduleActions.getDebriefingTrainingData(
              query.get("moduleId"),
              query.get("sessionId"),
              query.get("competencyName")
            );
          }
        })
        .finally(() => setLoading(false));
    } else if (query.get("mode") == "assessment") {
      userActions
        .getDebriefingData(
          query.get("moduleId"),
          query.get("sessionId"),
          query.get("competencyName")
        )
        .then((responce) => {
          if (responce.data.status) {
            moduleActions.getDebriefingDataLD(
              query.get("moduleId"),
              query.get("sessionId"),
              query.get("competencyName")
            );
          }
        })
        .finally(() => setLoading(false));
    }
  }, [query.get("moduleId"), query.get("sessionId")]);

  let result = module.deberiefReport?.debriefingData?.users?.reduce(function (
    r,
    a
  ) {
    r[a.role] = r[a.role] || [];
    r[a.role].push(a);
    return r;
  },
  Object.create(null));

  let result2 = module.deberiefReport?.debriefingData?.steps?.reduce(function (
    r,
    a
  ) {
    r[a.correctAction] = r[a.correctAction] || [];
    r[a.correctAction].push(a);
    return r;
  },
  Object.create(null));

  const exportToExcel = () => {
    const workbook = XLSX.utils.book_new();
    const debriefinData = [
      {
        CompetencyName: module.deberiefReport?.debriefingData
          ?.competencyResponse.competencyName
          ? module.deberiefReport?.debriefingData?.competencyResponse
              ?.competencyName
          : "N/A",

        ModuleName: module.deberiefReport?.debriefingData?.moduleId?.moduleName
          ? module.deberiefReport?.debriefingData?.moduleId?.moduleName
          : "N/A",

        UserName: module.deberiefReport?.debriefingData?.userId?.name
          ? module.deberiefReport?.debriefingData?.userId?.name
          : "N/A",

        UserEmail: module.deberiefReport?.debriefingData?.userId?.emailAddress
          ? module.deberiefReport?.debriefingData?.userId?.emailAddress
          : "N/A",

        EventTime: module.deberiefReport?.debriefingData?.eventTime
          ? `${new Date(
              module.deberiefReport?.debriefingData?.eventTime
            ).toLocaleDateString()} - ${new Date(
              module.deberiefReport?.debriefingData?.eventTime
            ).toLocaleTimeString()}`
          : "N/A",
      },
    ];

    const debriefinDataSheet = XLSX.utils.json_to_sheet(debriefinData, {
      header: [
        "CompetencyName",
        "ModuleName",
        "UserName",
        "UserEmail",
        "EventTime",
      ],
    });

    XLSX.utils.book_append_sheet(
      workbook,
      debriefinDataSheet,
      "Debriefing Details"
    );

    // Sheet 2: Steps with correctAction true
    const correctActionData = module.deberiefReport?.debriefingData?.steps
      .filter((step) => step.correctAction)
      .map((step) => ({
        Name: step.name || "N/A",
        Action: step.action || "N/A",
        Timestamp: step.timestamp || "N/A",
      }));

    const correctActionSheet = XLSX.utils.json_to_sheet(correctActionData, {
      header: ["Name", "Action", "Timestamp"],
    });
    XLSX.utils.book_append_sheet(workbook, correctActionSheet, "Correct Steps");

    // Sheet 3: Steps with correctAction false
    const incorrectActionData = module.deberiefReport?.debriefingData?.steps
      .filter((step) => !step.correctAction)
      .map((step) => ({
        Name: step.name || "N/A",
        Action: step.action || "N/A",
        Timestamp: step.timestamp || "N/A",
      }));

    const incorrectActionSheet = XLSX.utils.json_to_sheet(incorrectActionData, {
      header: ["Name", "Action", "Timestamp"],
    });
    XLSX.utils.book_append_sheet(
      workbook,
      incorrectActionSheet,
      "Incorrect Steps"
    );

    // Sheet 4: Patient Assessment data
    const patientAssessmentData =
      module.deberiefReport?.debriefingData?.patientAssesment.map(
        (assessment) => ({
          Name: assessment.name || "N/A",
          Question: assessment.question || "N/A",
          Answer: assessment.answer || "N/A",
          Timestamp: assessment.timestamp || "N/A",
        })
      );

    const patientAssessmentSheet = XLSX.utils.json_to_sheet(
      patientAssessmentData,
      {
        header: ["Name", "Question", "Answer", "Timestamp"],
      }
    );
    XLSX.utils.book_append_sheet(
      workbook,
      patientAssessmentSheet,
      "Patient Assessment"
    );

    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });
    const data = new Blob([excelBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });

    saveAs(data, "Debriefing_Data.xlsx");
  };

  return (
    <>
      <div className="overview-cont">
        <div className="row">
          <div className="col-xl-12 col-lg-12 overview-left-area your-module">
            <MobileNavBar />
            <div className="d-flex justify-content-between align-items-center">
              <h2 className="right_hading d-none d-md-inline">Debriefing</h2>
              <div>
                <button
                  className="adcm-btn Cancel ms-1 force-return-but"
                  onClick={() => exportToExcel()}
                >
                  Export
                </button>
                <Link to={`/module-report/${modid}`}>
                  <button className="adcm-btn Cancel ms-1 force-return-but">
                    Return
                  </button>
                </Link>
              </div>
            </div>
            <div className="row">
              <div
                className={`${
                  query.get("mode") !== "training" ? "col-lg-8" : "col-lg-12"
                }`}
              >
                <div className="company-fillout p-3">
                  <div className="row align-items-end">
                    <div className="col-md-12">
                      <ul className="row custom-no-ul">
                        <li className="col-md-6">
                          <span>Name: </span>
                          {query.get("competencyName")}
                        </li>
                        <li className="col-md-6">
                          <span> Dept: </span>
                          {
                            module.deberiefReport?.debriefingData?.userId
                              ?.department?.[0]
                          }
                        </li>
                        <li className="col-md-6">
                          <span>Time/Date: </span>
                          {new Date(
                            module.deberiefReport?.debriefingData?.eventTime
                          ).toLocaleDateString()}
                        </li>
                        <li className="col-md-6">
                          <span>Session Host Name: </span>
                          {result?.["host"]?.[0].name || "N/A"}
                        </li>
                      </ul>
                      {/* </div> */}
                    </div>
                  </div>
                </div>
              </div>
              {query.get("mode") !== "training" && (
                <div className="col-lg-4">
                  <div className="company-fillout p-3">
                    <div className="row align-items-end">
                      <div className="col-md-12">
                        <div className="form-group mb-0 text-center">
                          <span>Score </span>
                          <p className="debriefinding_score">
                            {query.get("score")
                              ? query.get("score") + "%"
                              : "N/A"}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div className="row">
              <div className="col-12 col-md-12">
                <div className="company-fillout p-3">
                  <div className="row align-items-end">
                    <div className="col-md-12">
                      <h2 className="debriefinding-fill-heaing">
                        List of Participants
                      </h2>
                      {result?.["participant"]?.map((p, index) => {
                        if (index == result?.["participant"].length - 1)
                          return p.name;
                        else return p.name + ", ";
                      }) || "N/A"}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-lg-4">
                <div className="company-fillout p-3">
                  <div className="row">
                    <div className="col-md-12">
                      <h2 className="debriefinding-fill-heaing">
                        Correct Steps
                      </h2>
                      <div className="debriefinding_innerscroll_panel">
                        {result2?.["true"]?.length > 0
                          ? result2?.["true"].map((item, index) => {
                              return (
                                <div className="itembox" key={item._id}>
                                  <div className="row">
                                    <div className="col-8">
                                      <b>{item.name}</b>
                                    </div>
                                    <div className="col-4 text-end color-purple">
                                      {item.timestamp}
                                    </div>
                                    <div className="col-12">{item.action}</div>
                                  </div>
                                </div>
                              );
                            })
                          : "N/A"}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="company-fillout p-3">
                  <div className="row">
                    <div className="col-md-12">
                      <h2 className="debriefinding-fill-heaing">
                        Incorrect/Missed Steps
                      </h2>
                      <div className="debriefinding_innerscroll_panel">
                        {result2?.["false"]?.length > 0
                          ? result2?.["false"].map((item, index) => {
                              return (
                                <div className="itembox" key={item._id}>
                                  <div className="row">
                                    <div className="col-8">
                                      <b>{item.name}</b>
                                    </div>
                                    <div className="col-4 text-end color-purple">
                                      {item.timestamp}
                                    </div>
                                    <div className="col-12">{item.action}</div>
                                  </div>
                                </div>
                              );
                            })
                          : "N/A"}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {!loading ? (
                <></>
              ) : (
                <div className="loader-wrapper">
                  <div className="loader"></div>
                </div>
              )}
              <div className="col-lg-4">
                <div className="company-fillout p-3">
                  <div className="row">
                    <div className="col-md-12">
                      {/* <div className="form-group mb-0"> */}
                      <h2 className="debriefinding-fill-heaing">
                        Patient Assessment
                      </h2>
                      <div className="debriefinding_innerscroll_panel">
                        {module.deberiefReport?.debriefingData?.patientAssesment.map(
                          (item, index) => {
                            return (
                              <div className="itembox" key={item._id}>
                                <div className="row">
                                  <div className="col-8">
                                    <b>{item.name}</b>
                                  </div>
                                  <div className="col-4 text-end color-purple">
                                    {item.timestamp}
                                  </div>
                                  <div className="col-12">
                                    {"Question : " + item.question}
                                  </div>
                                  <div className="col-12">
                                    {"Answer : " + item.answer}
                                  </div>
                                </div>
                              </div>
                            );
                          }
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  user: state.auth.user,
  initialValues: state.users.selectedUser,
  token: state.auth.token,
  module: state.modules,
});

const mapDispatchToProps = (dispatch) => {
  return {
    moduleActions: bindActionCreators(moduleActions, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DebriefingPage);
