import { getInstance } from "./axiosFactory";

const axiosInstance = getInstance();

export const getAllDepartments = () => {
  return axiosInstance.get("/department/getalldepartments");
};

export const assignModules = (departments, modules) => {
  return axiosInstance.post("/department/assignmodules", {
    modules: modules,
    departments: departments,
  });
};

export const getDepartment = (id) => {
  return axiosInstance.get(`/department/getdepartment/${id}`);
};

export const updateDepartmentById = async (id, data) => {
  return await axiosInstance.put(`/department/updateDepartment/${id}`, data);
};

export const createDepartment = async (data) => {
  return await axiosInstance.post(`/department/createDepartment`, data);
};

export const getOasisDetails = async () => {
  return await axiosInstance.get(`/oasis/get-configuration`);
};

export const updateOasisDetails = async (data) => {
  return await axiosInstance.post(`/oasis/configuration`, data);
};
