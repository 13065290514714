import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import { connect } from "react-redux";
import * as userActions from "../../../action/userActions";
import * as companyActions from "../../../action/companyActions";
import { bindActionCreators } from "redux";
import DataGrid from "../../../common/dataGrid";
import { CSVLink } from "react-csv";
import { getDepartment } from "../../../action/departmentActions";
import EldcLogo from "../../../images/MedVR_Logo.png";
import MobileNavBar from "../../../components/mobileNavBar";

let ExportUsersPage = (props) => {
  const { use, auth } = props;
  const history = useHistory();
  let [loading, setLoading] = useState(false);
  let [usersAll, setUsersAll] = useState([]);
  let [sortColumn, setSortColumn] = useState("name");
  let [sortOrder, setSortOrder] = useState(1);
  let [pageNumber, setPageNumber] = useState(1);
  let [pageSize, setPageSize] = useState(10);
  let [usersCount, setUsersCount] = useState(0);
  const [department, setDepartment] = useState({});

  useEffect(() => {
    getDepartment(auth.user.departmentId[0]).then((result) =>
      setDepartment(result.data)
    );
  }, []);

  useEffect(() => {
    setLoading(true);
    userActions
      .searchUsers("")
      .then((response) => setUsersAll(response.data))
      .finally(() => setLoading(false));
  }, []);

  const onChangePage = (page, totalRows) => {
    setPageNumber(page);
  };

  const onSort = (column, sortDirection, event) => {
    setSortColumn(column.selector);
    setSortOrder(sortDirection === "asc" ? 1 : -1);
  };

  const onChangeRowsPerPage = (currentRowsPerPage, currentPage) => {
    setPageNumber(1);
    setPageSize(currentRowsPerPage);
  };

  const customStyles = {
    headCells: {
      style: {
        borderTop: "none",
        fontSize: "15px",
        color: "#2F4153",
        fontWeight: 700,
        "&:last-child": {
          textAlign: "right",
        },
      },
    },
  };

  const columns = [
    { name: "User Name", selector: "name", sortable: true, wrap: true },
    {
      name: "Email Address",
      selector: "emailAddress",
      sortable: true,
      wrap: true,
    },
    { name: "Role", selector: "role", sortable: true },
  ];

  let [exportUsers, setExportUsers] = useState([]);
  useEffect(() => {
    let arr = [];
    for (let i = 0; i < usersAll.length; i++) {
      let user = usersAll[i];
      delete user.departmentId;
      delete user.companyId;
      user.pin = user?.password?.passwordHash || null;
      user.userID = user.mobileNo;
      delete user.mobileNo;
      user.course = user.employeeId;
      delete user.employeeId;
      user.profilePic = user.profilePic ? user.profilePic.Location : null;
      // let modArr = [];
      // for (let j = 0; j < user.modules.length; j++) {
      //   modArr.push(user.modules[j]?.moduleId?.moduleName);
      // }
      // user.modules = modArr;

      delete user.status;
      delete user.firstTimeLogin;
      delete user.changePasswordAtFirstLogin;
      delete user.department;
      delete user._id;
      delete user.modules;
      delete user.__v;
      delete user.password;
      delete user.course;
      delete user.profilePic;
      delete user.gender;
      arr.push(user);
    }
    setExportUsers(arr);
  }, [usersAll]);

  let tableData = [];
  if (loading) {
    tableData.push(
      <td className="text-center" colspan="5">
        {!loading ?
                      <></>
                      :
                      <div className="loader-wrapper">
                        <div className="loader"></div>
                        
                      </div>

                    }
      </td>
    );
  }
  if (usersAll.length === 0 && !loading) {
    tableData.push(
      <td className="text-center" colspan="5">
        <h2>No data found !</h2>
      </td>
    );
  }

  if (usersAll.length !== 0) {
    usersAll?.map((item, index) => {
      tableData.push(
        <tr>
          <td>{item.name || "N/A"}</td>
          <td>{item.emailAddress || "N/A"}</td>
          <td className="text-center">{item.userID || "N/A"}</td>
          <td className="text-center">{item?.pin || "N/A"}</td>
          <td className="text-center">{item.role || "N/A"}</td>
          <td className="text-center">{item.location || "N/A"}</td>
        </tr>
      );
    });
  }

  let btnStyle = {
    border: "1 px solid #15bd8c",
    borderRadius: "3px",
    background: "#15bd8c",
    fontWeight: "400",
    fontSize: "14px",
    textAlign: "center",
    color: "#fff",
    textDecoration: "none",
    minWidth: "130px",
    letterSpacing: "0.0041em",
    display: "inline-block",
    height: "40px",
    lineHeight: "40px",
    marginTop: "1em",
    border: "none",
  };

  return (
    <>
      <div className="overview-cont">
        <div className="row">
          <div className="col-xl-12">
            <MobileNavBar />
            <div className="text-end mb-4">
              <CSVLink
                data={exportUsers}
                target="_blank"
                filename={`Users_list_export_${new Date().toLocaleDateString()}.csv`}
              >
                <button
                  type="button"
                  style={btnStyle}
                  className="adcm-btn ms-2"
                >
                  Export{" "}
                </button>
              </CSVLink>
            </div>
          </div>
          <div className="col-xl-12">
            <div className="modular-table table-responsive-xl">
              <table className="table align-middle-modify">
                <thead>
                  <tr scope="col">
                    <th>Name</th>
                    <th>Email Address</th>
                    <th>User ID</th>
                    <th>Password</th>
                    <th>Role</th>
                    <th>Location</th>
                  </tr>
                </thead>
                <tbody>{tableData}</tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    token: state.auth.token,
    user: state.auth.user,
    companies: state.companies,
    auth: state.auth,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    userActions: bindActionCreators(userActions, dispatch),
    companyActions: bindActionCreators(companyActions, dispatch),
    dispatch,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ExportUsersPage);
